import React from "react";
import Layout from "./pages/Layout";
import Header from "./components/Header";
import Footer from "./pages/Footer";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
// import Services from "./pages/Services/index.js";
// import Staffing from "./pages/Staffing/index.js";
import commerceDevelopment from "./pages/commerceDevelopment";
import hireDedicatedDeveloper from "./pages/hireDedicatedDeveloper";
import userExperienceDesign from "./pages/userExperienceDesign";
import mvpDevelopment from "./pages/mvpDevelopment";
import mobileAppDevelopment from "./pages/mobileAppDevelopment";
import webAppDevelopment from "./pages/webAppDevelopment";
import blockChainDevelopment from "./pages/blockChainDevelopment";
import getInTouch from "./pages/getInTouch";
import aboutUs from "./pages/aboutUs";
import OurServices from "./pages/OurServices";
import approach from "./pages/approach";
import carrers from "./pages/carrers";
import ClassGames from "./pages/classGames";
import "./styles/bootstrap.scss";
import "./App.scss";

// const About = React.lazy(() => import("./pages/About"));
// const Dashboard = React.lazy(() => import("./pages/Dashboard"));

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        {/* Header Section */}
        <Header />

        {/* Layout Section */}
        <Layout>
          <Switch>
            <Route path="/about">About Us</Route>
            <Route path="/users">Usersdd</Route>
            <Route exact path="/" component={withRouter(Home)} />
            {/* <Route exact path="/Staffing" component={withRouter(Staffing)} /> */}
            <Route
              exact
              path="/commerceDevelopment"
              component={withRouter(commerceDevelopment)}
            />
            <Route
              exact
              path="/hireDedicatedDeveloper"
              component={withRouter(hireDedicatedDeveloper)}
            />
            <Route
              exact
              path="/userExperienceDesign"
              component={withRouter(userExperienceDesign)}
            />
            <Route
              exact
              path="/mvpDevelopment"
              component={withRouter(mvpDevelopment)}
            />
            <Route
              exact
              path="/mobileAppDevelopment"
              component={withRouter(mobileAppDevelopment)}
            />
            <Route
              exact
              path="/webAppDevelopment"
              component={withRouter(webAppDevelopment)}
            />
            <Route
              exact
              path="/blockChainDevelopment"
              component={withRouter(blockChainDevelopment)}
            />
            <Route
              exact
              path="/getInTouch"
              component={withRouter(getInTouch)}
            />
            <Route exact path="/aboutUs" component={withRouter(aboutUs)} />
            <Route exact path="/Services" component={withRouter(OurServices)} />
            <Route exact path="/approach" component={withRouter(approach)} />
            <Route exact path="/carrers" component={withRouter(carrers)} />
            <Route
              exact
              path="/class2Mobile"
              component={withRouter(ClassGames)}
            />
            {/* <Route exact path={`${window.location.pathname}`} component={withRouter(Services)} />  */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Layout>

        {/* Footer Section - contains footer menu, get in touch button text block */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
