import { React, useState, useEffect } from "react";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

import health from "../../images/health.svg";
import media from "../../images/media.svg";
import fintech from "../../images/fintech.svg";
import analytics from "../../images/analytics.svg";
import gaming from "../../images/gaming.svg";
import market from "../../images/market.svg";
import home_unsplash from "../../images/home_unsplash.png"



import rectangle from "../../images/Rectangle.svg"
import './home.scss';
import { withRouter } from "react-router";
import 'bootstrap/dist/css/bootstrap.css';
// import Carousel from 'react-bootstrap/Carousel';

// import image1 from '../../images/image1.png';
import industries from '../../images/industries.png';
import logo1 from '../../images/clients/logo1.png';
import logo2 from '../../images/clients/logo2.png';
import logo3 from '../../images/clients/logo3.png';
import logo4 from '../../images/clients/logo4.png';
import logo5 from '../../images/clients/logo5.png';
import logo6 from '../../images/clients/logo6.png';
import logo7 from '../../images/clients/logo7.png';
import logo8 from '../../images/clients/logo8.png';
import logo9 from '../../images/clients/logo9.png';
import icon1 from "../../images/user/icon1.svg"
import icon3 from "../../images/web/icon1.svg"
import icon2 from "../../images/icon2.svg"
import whiterectangle from "../../images/whiterectangle.png"


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import {triggerDatainMixpanel} from "../../common";
const Home = () => {
  const [isActive, setActive] = useState("");
  useEffect(() => {
    triggerDatainMixpanel("home_page");
  }, []);
  const ToggleClass = (e) => {
    setActive(prev => prev === e ? "" : e);
  };
  return (

    <div className="main">
      <div className="header" >
        <h1>WORLD - CLASS DIGITAL PRODUCTS.<br />
  ON-<span >TIME</span>. ON-<span>BUDGET</span>. ON-<span>POINT</span>.</h1>
        <p>We are an acclaimed and certified web and mobile app design and development <br />
  company committed to digital innovations and inventions.</p>
      </div>
      <div className="client" >
        <div className="row">
          <div className="col-md-2"><img src={logo1}></img></div>
          <div className="col-md-2"><img src={logo2}></img></div>
          <div className="col-md-2"><img src={logo3}></img></div>
          <div className="col-md-2"><img src={logo4}></img></div>
          <div className="col-md-2"><img src={logo5}></img></div>
          <div className="col-md-2"></div>
        </div><br />
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-2"><img src={logo6}></img></div>
          <div className="col-md-2"><img src={logo7}></img></div>
          <div className="col-md-2"><img src={logo8}></img></div>
          <div className="col-md-2"><img src={logo9}></img></div>


        </div>
      </div>
      <div className="mainFullWidthPage">
        <div className="leftSideImageMain">
          <div>
            <img src={home_unsplash}></img>
          </div>
        </div>
        <div className="rightSideCaroselMain">
          <Carousel
            showArrows={true}
            showThumbs={false}
            showStatus={false}
          >
            <div className="carouselNames">
              <div className="slider1">
                <div className="row" >
                  <div className="col-md-6 innersection1" >

                    <h5>OUR SERVICES</h5>
                    <img src={rectangle}></img>
                    <h1>What  <br /> we do</h1>

                  </div>

                  <div className="col-md-6 innersection2" >
                    <img src={icon2}></img>
                    <h3>User Experience</h3>
                    <p>Origami applies Agile methdology to create user experiences and deliver design innovations that
                    Promise you functional and scalable products. we work with you to develop products that will
             Skyrocket your sales and boost your Profits while delivering delightful experience to your customers. </p>
                  </div>


                  <div className="col-md-6 innersection3" >
                    <img src={icon1}></img>
                    <h3>Design Research</h3>
                    <p>At the base of any successful Product is equality research-based innovation and implementation.
                    We, at Origami, Understand this simple Principle and apply it in all our projects be it a start-up
                    or an SME. Continuous collaboration with clients help us conduct research which promises consistent
            service and innovative solutions.</p>
                  </div>
                  <div className="col-md-6 innersection4" >
                    <img src={icon3}></img>
                    <h3>Experience strategy</h3>
                    <p>We value the importance of customer experience in any business. Therefore, we define your vision
                    and align our development strategies to that of your business’ objectives. This results in seamless
             development & Implementation. Afterall, happy customers lead to healthy business.</p>
                  </div>
                </div>

              </div>
            </div>
            <div className="carouselNames">
              <div className="slider2">
                <div className="row section2 " >
                  <div className="col-md-6 sliderinner1" >

                    <h5>OUR SERVICES</h5>
                    <img src={whiterectangle}></img>
                    <h1>What  <br /> we do</h1>
                    <h3>One stop Solution</h3>
                    <p>Origami is a disruptive product innovation design agency providing end-to-end technology
                    services to start-ups and SMEs. We welcome your trickiest and most trying Products Innovation,
             Startup Ideas, digital product design. The more complicated, the better. </p>
                  </div>

                  <div className="col-md-6  sliderinner2" >
                    <div className="sliderdeepinner">
                      <ul>
                        <li>Commerce development</li>
                        <li>Hire dedicated developer </li>
                        <li>User experience design </li>
                        <li> MVP Development</li>
                        <li>Mobile App development</li>
                        <li>Web App development </li>
                        <li>Blockchain Development</li><br /><br /><br />
                        <li>Learn more about services <b>---</b></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Carousel>
        </div>
      </div>



      <div className="work">
        <div className="row">

          <div className="column" >
            <div className="industries" >
              <h1>Industries <br /> we work</h1>
              <p>Origami has aspired global businesses to use the technologies of today to build a better tomorrow.
              Let us shake hands and gain immediate access to our top tech talent, like your own team, for building
              great software solutions. </p>
            </div>
            <div className="containernew" >
              <div class="grid-container">
                <div class="grid-child ">
                  <img src={gaming} ></img>
                  <p>Fantasy Gaming</p>
                </div>
                <div class="grid-child ">
                  <img src={health} ></img>
                  <p>Healthcare</p>
                </div>
                <div class="grid-child ">
                  <img src={analytics} ></img>
                  <p>Analytics</p>
                </div>
                <div class="grid-child ">
                  <img src={fintech} ></img>
                  <p>Fintech</p>
                </div>
                <div class="grid-child ">
                  <img src={media} ></img>
                  <p>Entertainment & <br />
  Media</p>
                </div>
                <div class="grid-child ">
                  <img src={market}></img>
                  <p>Ecommerce &
  Marketplace</p>
                </div>


              </div>
            </div>


          </div>


          <div className="column" >
            <img className="d-block w-100" src={industries} alt="Image One" />
          </div>
        </div>
      </div>

    </div>


  )
}

export default withRouter(Home);