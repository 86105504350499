import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router";
import icon1 from "../../images/mobile/icon1.svg";
import icon2 from "../../images/mobile/icon2.svg";
import icon3 from "../../images/mobile/icon3.svg";
import mobileapp from "../../images/mobile/mobileapp.png";
import headerimg from "../../images/mobile/headerimg.jpg";
import "./style.css";
import { Button } from "react-bootstrap";

import GetRequirementForm from "../../components/GetRequirementForm";

const mobileAppDevelopment = () => {
  return (
    <div className="content">
      <div className="mobileheader">
        <img src={headerimg}></img>
        <div className="mobilecentered">
          <h1>Mobile App Development </h1>
          <h3>
            We build advanced mobile app solutions for startups, SMEs, and
            large-scale enterprises.
          </h3>
          <h5>
            Hire professional mobile app developers from us to craft
            feature-rich and robust mobile applications, be it for android, iOS,
            or any other platform.
          </h5>
          <center>
            <Button>
              <a href="/getInTouch">Get a free quote </a>
            </Button>
          </center>
        </div>
      </div>
      <div className="development">
        <h1>Our Mobile App Development Process </h1>
        <p>
          We follow a predefined mobile app development process that includes
          analysis of
          <br /> requirements, wire-framing, development, testing, and
          deployment, specific to industry for
          <br /> delivering the best solution.
        </p>
        <div className="process">
          <div className="row">
            <div className="benefitsCol col-md-4">
              <div className="benefitsCardBox card">
                <div className="card-body">
                  <img src={icon2}></img>
                  <div className="benefitsCardTitleText card-title h5">
                    Pre-design
                  </div>
                  <p className="cardTextBenefits card-text">
                    In the first step of app development, we make sure that
                    everything is worked out like what would be the final
                    product, whom it’s targeted, what technologies it should
                    use, and how we can measure the success to ensure it is a
                    great final product.
                  </p>
                </div>
              </div>
            </div>
            <div className="benefitsCol col-md-4">
              <div className="benefitsCardBox card">
                <div className="card-body">
                  <img src={icon1}></img>
                  <div className="benefitsCardTitleText card-title h5">
                    Design
                  </div>
                  <p className="cardTextBenefits card-text">
                    Once the pre-design stage is completed, we adapt the full
                    recommendation approach to move on to your application’s UI
                    & UX design by ensuring that it is entirely conceived. In
                    short, we start making a wireframe of your product with a
                    detailed analysis of the prototype if required.
                  </p>
                </div>
              </div>
            </div>
            <div className="benefitsCol col-md-4">
              <div className="benefitsCardBox card">
                <div className="card-body">
                  <img src={icon3}></img>
                  <div className="benefitsCardTitleText card-title h5">
                    Development
                  </div>
                  <p className="cardTextBenefits card-text">
                    After the pre-design and design stage, we allocate our
                    skilled and experienced mobile app resources to develop a
                    successful digital product. Once your app is ready to
                    appear, our developers will help you deploy it on the app
                    store or play store.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="marketplacecontent">
        <div className="mobilemarketplace">
          {/* <img src={mobileapp}></img> */}
          <div className="mobiletextblock">
            <h3>Playsqor</h3>
            <p>
              Playsqor is a U.S. based company that offers a 100% legal Sports
              Matchup Game.
              <br /> It was created by some of the top talents in sports gaming
              in conjunction <br />
              with leading U.S
            </p>
            <Button>
              <a target="_blank" href="https://vetnos.co/">
                View Project
              </a>
            </Button>
          </div>
        </div>
      </div>
      <div className="development">
        <h1>Our Mobile App Development Services</h1>
        <p>
          We provide full-cycle mobile app development services right from
          business analysis to your <br />
          mobile app’s design and development. Our experienced mobile app
          developers will help you
          <br /> build mobile apps as per your business requirements, in the
          budget, and as your timeline.
        </p>
        <div className="process">
          <div className="row">
            <div className="benefitsCol col-md-4">
              <div className="benefitsCardBox card">
                <div className="card-body">
                  <div className="benefitsCardTitleText card-title h5">
                    ANDROID APP DEVELOPMENT
                  </div>
                  <p className="cardTextBenefits card-text">
                    With the deep understanding and expertise of Android app
                    development, we help you build secure, scalable, and
                    engaging android applications that fully meet your
                    requirements and even exceed your user’s expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="benefitsCol col-md-4">
              <div className="benefitsCardBox card">
                <div className="card-body">
                  <div className="benefitsCardTitleText card-title h5">
                    IPHONE APP DEVELOPMENT
                  </div>
                  <p className="cardTextBenefits card-text">
                    Leverage the expertise of our iPhone app developers in
                    offering high-quality iPhone app development services and
                    build stunning, fully-functional and high-performing apps
                    that add value to your business and your user’s lives.
                  </p>
                </div>
              </div>
            </div>
            <div className="benefitsCol col-md-4">
              <div className="benefitsCardBox card">
                <div className="card-body">
                  <div className="benefitsCardTitleText card-title h5">
                    IPAD APP DEVELOPMENT
                  </div>
                  <p className="cardTextBenefits card-text">
                    Develop best-in-class native iPad apps with the help of our
                    skilled and experienced full-stack iPad app developers. Our
                    developers use the latest technology and industry experience
                    to turn your app idea into reality.
                  </p>
                </div>
              </div>
            </div>
            <div className="benefitsCol col-md-4">
              <div className="benefitsCardBox card">
                <div className="card-body">
                  <div className="benefitsCardTitleText card-title h5">
                    CROSS-PLATFORM APP DEVELOPMENT
                  </div>
                  <p className="cardTextBenefits card-text">
                    We have top-of-the-line expertise in building mobile app
                    solutions that run smoothly on multiple platforms like
                    Android and iOS. Hire cross-platform mobile app developers
                    from us to develop apps in trending technologies like React
                    Native, Flutter, Xamarian, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="benefitsCol col-md-4">
              <div className="benefitsCardBox card">
                <div className="card-body">
                  <div className="benefitsCardTitleText card-title h5">
                    AR & VR APP DEVELOPMENT
                  </div>
                  <p className="cardTextBenefits card-text">
                    With our AR & VR mobile app development services, you can
                    build interactive mobile experiences such as AR navigation,
                    virtual tours, GEO-based AR mobile apps, and whatnot. Hire
                    our professional AR & VR mobile app developers to stay well
                    ahead of your peers.
                  </p>
                </div>
              </div>
            </div>
            <div className="benefitsCol col-md-4">
              <div className="benefitsCardBox card">
                <div className="card-body">
                  <div className="benefitsCardTitleText card-title h5">
                    WEARABLE DEVICE APP DEVELOPMENT
                  </div>
                  <p className="cardTextBenefits card-text">
                    Hire our seasoned wearable experts and build creative yet
                    wonderful wearable device apps that are customer-centric and
                    exclusive in all the technical aspects. Our technical
                    experts will make sure that your wearable device app meets
                    your predefined requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="getintouch">
        <div className="row">
          <div className="details1 col-md-4">
            <h6>Get in touch</h6>
            <p>with us to know more</p>
          </div>
          <div className="details2 col-md-8">
            <h6>
              At Origami, Our team of dedicated MVP developers brings your ideas
              to the life and develops world-class B2B & B2C applications best
              suited for your business needs.
            </h6>
          </div>
        </div>
      </div>
      <GetRequirementForm title="Let’s talk about your mobile app idea and get started on your project today!" />
    </div>
  );
};

export default withRouter(mobileAppDevelopment);
