import React from 'react';
import * as Icon from "react-feather";
import { ReactComponent as RedHeart } from "../../images/redHeart.svg";

const FooterCopyright = () => {
  const date =  new Date().getFullYear();
  return (
    <div className="og-footer-copyright d-md-flex justify-content-lg-between">
      <div className="p-2 bd-highlight">
        Made with <RedHeart width="18" color="red" /> from the people of
        Origami
      </div>
      <div className="p-2 bd-highlight">
        @ {date} Origami. All Rights Reserved.
      </div>
    </div>
  );
};

export default FooterCopyright;
