import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from "react-router";
import icon1 from "../../images/mvp/icon1.svg"
import icon2 from "../../images/mvp/icon2.svg"
import icon3 from "../../images/mvp/icon3.svg"
import mvp from "../../images/mvp/mvp.png"
import headerimg from "../../images/mvp/headerimg.jpg" 
import './style.css';
import { Button } from "react-bootstrap";

import GetRequirementForm from "../../components/GetRequirementForm";

const mvpDevelopment = () => {
    return (
        <div className="content"> 
            <div className="mvpheader" >
                <img src={headerimg}></img>
                <div className="mvpcentered">
                    <h1>MVP Development</h1>
                    <h3>Looking to validate your MVP idea and its viability?

        </h3>
                    <h5>We help you develop innovative, secure, and high-quality solutions that will help
                    in optimizing your path to a successful
minimum viable product.</h5>
                    <center><Button><a href="/getInTouch">Get a free quote</a> </Button></center>
                </div>
            </div>
            <div className="development">
                <h1>Our MVP Development Process</h1>
                <p>Being a top-notch MVP development company, we follow a<br />
definite process to launch a dynamic and scalable product within the given timeframe.</p>
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon1}></img>
                                    <div className="benefitsCardTitleText card-title h5">Define
the idea</div>
                                    <p className="cardTextBenefits card-text">In this primary phase, we decide what features you must include in your product to be identified easily in the market. Also, we find your MVP’s value proposition (VPC) to figure out the essential factors while expressing your idea to the customers.



                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon2}></img>
                                    <div className="benefitsCardTitleText card-title h5">Design
user flow</div>
                                    <p className="cardTextBenefits card-text">At this stage, we provide experienced designers (UI/UX) that have top-of-the-line expertise in building prototypes incorporating features to collect the data and feedback. From opening the app to the final process, we will help you map out the user flow.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon3}></img>
                                    <div className="benefitsCardTitleText card-title h5">Develop
the MVP</div>
                                    <p className="cardTextBenefits card-text">With our proven expertise in product thinking and accelerating innovation, we leverage frameworks like VPC and MoSCoW that have an agile delivery model to reduce the risks of new technology adoption and achieve the goals of developing MVP faster in the market.



                       </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="marketplacecontent">
                <div className="mvpmarketplace">
                    {/* <img src={mvp}></img> */}
                    <div className="textblock">
                        <h3>Wholosophy</h3>
                        <p>Wholosophy is social networking site for holistic-minded
                   people <br />and a wellness practitioner directory. Social and ...growth
</p>
                        <Button><a href="https://www.wholosophy.com/">View Project</a></Button>
                    </div>
                </div>
            </div>
            <div className="development">
                <h1>MVP Development Services</h1>
                <p>We offer end-to-end MVP development services to start-ups and entrepreneurs right from<br />
       product strategy development to testing, release, and ongoing maintenance services. <br />
       Develop the right solution for your business with us and upscale it to the next level.


</p>
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">MVP MOBILE APP DEVELOPMENT

</div>
                                    <p className="cardTextBenefits card-text">Leverage our expertise in developing an MVP and turn your mobile app idea into reality. Our mobile app developers have in-depth knowledge and extensive experience to build fully-functional and responsive mobile apps.
                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">MVP WEB APPLICATION DEVELOPMENT</div>
                                    <p className="cardTextBenefits card-text">We develop operational MVP web applications for enterprises and start-ups to lower the cost of maintaining two different mobile apps. Hire us to create a web app that is deployed with modern JavaScript and add value to your business.



                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">CUSTOM SOFTWARE DEVELOPMENT
</div>
                                    <p className="cardTextBenefits card-text">Being a leading MVP development company, we help you build tailor-made and unique software that meets your specific enterprise needs in the market. Hire MVP software development team from us and create the right solution.




                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">MVP CONSULTING
                                    SERVICES

</div>
                                    <p className="cardTextBenefits card-text">Hire MVP developers from us and assist your MVP start-up that delivers results. With our high-quality MVP consulting services, you can build experiences that will allow you to develop an out-of-the-box minimum viable product for your future users.




                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">PROTOTYPE DESIGN
</div>
                                    <p className="cardTextBenefits card-text">Unleash your business growth with our skilled and experienced MVP developers, having expertise in building interactive prototype designs that help your enterprise in developing or improving the product with great ideas and suggestions.




                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">SINGLE FEATURE/PILOT MVP DEVELOPMENT
</div>
                                    <p className="cardTextBenefits card-text">We offer best-in-class MVP development services to develop a single feature MVP that offers improved user-experience, increased engagement, and high-performance as well as transforms your MVP from a single feature to an advanced product with pilot MVP development.


                       </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="getintouch">
                <div className="row">
                    <div className="details1 col-md-4">
                        <h6>Get in touch</h6>
                        <p>with us to know more</p>
                    </div>
                    <div className="details2 col-md-8">
                        <h6>Origami has established reputation and respect from clients all across the world by delivering creative, reliable, and secure mobile app solutions for all the niches.
</h6>
                    </div>
                </div>

            </div>
            <GetRequirementForm 
            title ="Fill up form and schedule free consultation"
            />
        </div>

    );
}

export default withRouter(mvpDevelopment);