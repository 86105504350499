import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from "react-router";
import serviceheader from "../../images/services/serviceheader.png" 
import service1 from "../../images/services/service1.png"
import service2 from "../../images/services/service2.png"
import service3 from "../../images/services/service3.png" 
import service4 from "../../images/services/service4.png"
import line from "../../images/services/line.png"    
import figma from "../../images/staffing/tech/figma.svg";
import sketch from "../../images/staffing/tech/sketch.svg"
import adobe from "../../images/staffing/tech/adobe.svg"
import ps from "../../images/staffing/tech/ps.svg" 
import js from "../../images/services/frontend/js.png" 
import html from "../../images/services/frontend/html.png"
import css from "../../images/services/frontend/css.png"
import react from "../../images/services/frontend/react.png"
import react_native from "../../images/services/frontend/react_native.png"
import angular from "../../images/services/frontend/angular.png"
import flutter from "../../images/services/frontend/flutter.png"
import npm from "../../images/services/frontend/npm.png"
import vue from "../../images/services/frontend/vue.png"
import iconic from "../../images/services/frontend/iconic.png"
import bootstrap from "../../images/services/frontend/bootstrap.png"
import dotnet from "../../images/staffing/tech/dotnet.svg"
import node from "../../images/staffing/tech/node.svg"
import ruby from "../../images/staffing/tech/ruby.svg"
import laravel from "../../images/staffing/tech/laravel.svg"
import php from "../../images/staffing/tech/php.svg"
import golang from "../../images/staffing/tech/golang.svg"
import java from "../../images/services/java.png"
import ios from "../../images/staffing/tech/ios.svg"
import android from "../../images/staffing/tech/android.svg"
import kotlin from "../../images/staffing/tech/kotlin.svg"
import google from "../../images/staffing/tech/google.svg"
import aws from "../../images/staffing/tech/aws.svg"
import digital from "../../images/staffing/tech/digital.svg"
import heroku from "../../images/staffing/tech/heroku.svg"
import sql from "../../images/staffing/tech/sql.svg"
import mongo from "../../images/staffing/tech/mongo.svg"
import postsql from "../../images/staffing/tech/postsql.svg"
import mysql from "../../images/staffing/tech/mysql.svg"
import dynamo from "../../images/staffing/tech/dynamo.svg"
import bigcommerce from "../../images/services/ecommerce/bigcommerce.png"
import magento from "../../images/services/ecommerce/magento.png"
import volusion from "../../images/services/ecommerce/volusion.png"
import salesforce from "../../images/services/ecommerce/salesforce.png"
import woo from "../../images/services/ecommerce/woo.png"
import shift from "../../images/services/ecommerce/shift.png"
import shopify from "../../images/services/ecommerce/shopify.png"
import kibo from "../../images/services/ecommerce/kibo.png"
import git from "../../images/services/ecommerce/git.png"
import jira from "../../images/staffing/tech/jira.svg"
import trillo from "../../images/staffing/tech/trillo.svg"
import basecamp from "../../images/staffing/tech/basecamp.svg"
import ms from "../../images/staffing/tech/ms.svg"
import apache from "../../images/staffing/tech/apache.svg"
import xcode from "../../images/staffing/tech/xcode.svg"
import fastlane from "../../images/staffing/tech/fastlane.svg"
import gradle from "../../images/staffing/tech/gradle.svg"
import maven from "../../images/staffing/tech/maven.svg"
import anisble from "../../images/staffing/tech/anisble.svg"
import chef from "../../images/staffing/tech/chef.svg"
import puppet from "../../images/staffing/tech/puppet.svg"
import wordpress from "../../images/staffing/tech/wordpress.svg"
import drupal from "../../images/staffing/tech/drupal.svg"
import github from "../../images/staffing/tech/github.svg"
import bitbucket from "../../images/staffing/tech/bitbucket.svg"
import gitlab from "../../images/staffing/tech/gitlab.svg"
import other from "../../images/staffing/tech/other.svg"
import slack from "../../images/services/comm/slack.png"
import msteam from "../../images/services/comm/msteam.png"
import hangout from "../../images/services/comm/hangout.png"
import meet from "../../images/staffing/tech/meet.svg"
import zoom from "../../images/staffing/tech/zoom.svg"
import icon1 from "../../images/serve/icon1.svg"
import icon2 from "../../images/serve/icon2.svg"
import icon3 from "../../images/serve/icon3.svg"
import icon4 from "../../images/serve/icon4.svg"
import icon5 from "../../images/serve/icon5.svg"
import icon6 from "../../images/serve/icon6.svg"
import {
    useLocation,
    useHistory,
  } from "react-router-dom";


import './style.css';



const OurServices = ()=>{
    const history = useHistory();
    const gotoPage = (url)=>{
        history.push(url)
        window.scrollTo(0, 0);
      }
    return(
        <div className="content">
        
<div className="service"> 
    <div className="row">
        <div className="servicenew1 col-md-6">
        
            <h3>Our Services</h3>
           <h4>Identify your next bold & innovative move in your industry</h4>
           <div className="serviceheader" >
           <div className="row serviceheaderContent">
            <div className="col-md-6">
            <p ><a href="/commerceDevelopment">Commerce development </a> </p>
            </div>
            <div className="col-md-6">
            <p><a href="/hireDedicatedDeveloper">Hire dedicated developer </a> </p> 
            </div>
            <div className="col-md-6">
            <p><a href="/userExperienceDesign"> User experience design </a> </p>
            </div>
            <div className="col-md-6">
            <p><a href="/mvpDevelopment">MVP Development</a> </p> 
            </div>
            <div className="col-md-6">
            <p><a href="/mobileAppDevelopment">Mobile App development</a></p>
            </div>
            <div className="col-md-6">
            <p><a href="/webAppDevelopment">Web App development</a></p> 
            </div>
            <div className="col-md-6">
            <p><a href="/blockChainDevelopment">Blockchain Development</a></p>
            </div>
            
           </div>
           </div>
        
         </div>
                 
         <div className="serviceaboutnew2 col-md-6">
         <img src={serviceheader}></img>
         </div>
    </div>

</div>

<div className="service1">
    <div className="row">
        <div className="servicesection1 col-md-6">
        
           <h3>Disruptive Innovation</h3>
           <p>Businesses should grow, manifold. And this is the reason that drives our team at Origami to bring
                in the best innovation strategy framework to you, so that they can take the next step forward
                 into delighting your customers. Business and technology are both dynamic concepts.
            <br/><br/>
           Through our services, you can identify your next bold & innovative move in your industry.
            Using our unique growth hacking techniques in business consulting, we conduct quantitative 
            and qualitative research. Our services include exploring your customer base, testing hypotheses, 
            evaluating results, and working towards bringing to you the best business innovation strategy.</p>
           <div className="serviceheader1" >
           <div className="row">
            <div className="col-md-3">
            <p>RESEARCH</p>
            </div>
            <div className="col-md-3">
            <p>STRATEGY</p> 
            </div>
            <div className="col-md-3">
            <p>IDEATION </p>
            </div>
            <div className="col-md-3">
            <p>EXPLORATION </p> 
            </div>

           </div>
           </div>
        
         </div>
                 
         <div className="servicesection2 col-md-6">
         <img src={service1}></img>
         </div>
    </div>

</div>
<div className="service2">
    <div className="row">
    <div className="servicesection3 col-md-6">
         <img src={service2}></img>
         </div>
        <div className="servicesection4 col-md-6">
        
           <h3>Experience Strategy</h3>
           <p>As screens get smaller our creative ambitions grow bigger. Your business’ growth, revenues, 
               and ROI depend greatly on your customers’ user experience while they are using your product.
                Everything, from the colour, the font, to the logo stands to differentiate you from your 
                competitors and earns you that extra brownie point; popularly known as the loyal customer base.
           <br/><br/>
         Your brand is the face of your business. With over 13 years of experience and our unique innovation 
         strategy, we come up with User Experiences and User Interfaces that become the face of your business. 
         Our products and designs are not only aesthetically attractive but also functional, scalable, and 
         extremely user friendly.
          
        </p>
           <div className="serviceheader2" >
           <div className="row">
            <div className="col-md-3">
            <p>CREATIVITY</p>
            </div>
            <div className="col-md-3">
            <p>INNOVATION</p> 
            </div>
            <div className="col-md-3">
            <p>VISUALDESIGN </p>
            </div>
            <div className="col-md-3">
            <p>BRANDING </p> 
            </div>
             
           </div>
           </div>
        
         </div>
                 
         
    </div>

</div>
<div className="service3">
    <div className="row">
        <div className="servicesection1 col-md-6">
        
           <h3>Technology Implementation</h3>
           <p>Continuous collaboration is the core of providing holistic services. We work alongside our
                clients at each stage of the production process – especially when we breathe new life into
                 your prototype for the very first time. Then you can test it, twist it, swipe it, push it 
                 and most importantly make sure it works.
           <br/><br/>
          We ensure that systems are in place to support massive page hits and customer interaction by 
          ensuring the product is scalable. Your business should expand without facing the worries of system
           crashes and web traffic overloads.
            
          </p>
           <div className="serviceheader1" >
           <div className="row">
            <div className="col-md-3">
            <p>PROTOTYPE</p>
            </div>
            <div className="col-md-3">
            <p>DEVELOPMENT</p> 
            </div>
            

           </div>
           </div>
        
         </div>
                 
         <div className="servicesection2 col-md-6">
         <img src={service3}></img>
         </div>
    </div>

</div>

<div className="service4">
    <div className="row">
    <div className="servicesection3 col-md-6">
         <img src={service4}></img>
         </div>
        <div className="servicesection4 col-md-6">
        
           <h3>Continuous Support</h3>
           <p>Once any product is launched, clients need help with navigation and occasional troubleshooting.
                We make sure that we are there, every step of the way to fulfil all your post-launch needs,
                 as well. Continued customer service and support lies at the base of our ethos and something
                  that we take very seriously, here at Origami.
          
        </p>
           <div className="serviceheader2" >
           <div className="row">
            <div className="col-md-3">
            <p>SYSTEM</p>
            </div>
            <div className="col-md-3">
            <p>TECHNOLOGY</p> 
            </div>
            
             
           </div>
           </div>
        
         </div>
                 
         
    </div>

</div>
        <div className="expertise">
            <h1>OUR EXPERTISE</h1>
        </div>
        <div className="expertisecontent">
        <div className="row">
        
        <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox card">
                <div className="card-body">
                <img src={icon1}></img>
                        <div className="benefitsCardTitleText card-title h5">Innovation <br/>Strategy</div>
                        <p className="cardTextBenefits card-text">With our unique innovation strategy and consulting, we help you take your offerings to the next levels. Why keep offering the same services when you give your customer the next disruptive innovation offering? We help you proactively focus on customers’ pain points and address them effectively.
  
                         </p>
                         </div>
                         </div>
                         </div>
                         <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox card">
                <div className="card-body">
                <img src={icon2}></img>
                        <div className="benefitsCardTitleText card-title h5">Experience <br/> Design</div>
                        <p className="cardTextBenefits card-text">One of the fundamentals of successful product development is creating a valuable user experience with flawless user interface and an experience which is both functional and scalable. Origami ensures that it sticks to this fundamental principle in all its projects and through all its efforts.
                         </p>
                         </div>
                         </div>
                         </div>
                         <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox card">
                <div className="card-body">
                <img src={icon3}></img>
                        <div className="benefitsCardTitleText card-title h5">Agile <br/>Development</div>
                        <p className="cardTextBenefits card-text">Product development although a planned process, often navigates from its course of workflow due to the many alterations and adaptations that are required. To ensure we continue we undertake the same route, however many variations take place, we offer to you an Agile delivery team that undertakes continuous updation, effective communication, and clear reporting. This not only makes sure that your project is right on track but also that the changes does affect your project cost.
  
                         </p>
                         </div>
                         </div>

                         </div>
                         <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox card">
                <div className="card-body">
                <img src={icon4}></img>
                        <div className="benefitsCardTitleText card-title h5">Product <br/>Management</div>
                        <p className="cardTextBenefits card-text">Since the start of the product development life cycle, until the very end, we ensure seamless and smooth execution of our project ideas and innovations. Our agile team consisting of various skills and capabilities helps us attain this goal. They not only help build your project from the scratch but also help it scale when required. Be it developers, engineers, security specialists, UX/UI designers, or business analysts, we have them all on our team.
  
                         </p>
                         </div>
                         </div>
                         </div>
                         <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox card">
                <div className="card-body">
                <img src={icon5}></img>
                        <div className="benefitsCardTitleText card-title h5">Enterprise <br/>Application</div>
                        <p className="cardTextBenefits card-text">With over 13 years of experience, Origami brings to you the product development expertise you need to boost and scale your business. We innovate and create disruptive products for you through a process of continuous development, testing, implementation, and collaboration. We do not leave any gap for errors by conductive continuous tests and welcoming feedback at every stage of the product development life cycle.

  
                         </p>
                         </div>
                         </div>
                         </div>
                         <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox card">
                <div className="card-body">
                <img src={icon6}></img>
                        <div className="benefitsCardTitleText card-title h5">Continuous <br/>Delivery</div>
                        <p className="cardTextBenefits card-text">Our work does not just end at handing over the product to you. You can bank on us for continuous support and service. Whether you would like to scale your product due to increased demands, or build on the product you already have, we are there with you every step of the way. This process of constant testing, monitoring, and deployment is made possible through our agile team.
  
                         </p>
                         </div>
                         </div>
                         </div>
                         </div> 
        </div>
         
        <div className="technologies">
            <h1>Technologies we work on</h1>
        </div>
        <div className="design">
            <h2>Design <img src={line}></img></h2> 
            <div className="designelements">
            <div className="row">
                <div className="element1 col-md-4">
                <img src={figma} /> Figma
                </div>
                
                <div className="element3 col-md-4">
                <img src={adobe} /> Adobe Illustrator
                </div>
                <div className="element4 col-md-4">
                <img src={ps} /> Adobe Photoshop
                </div>
                <div className="element2 col-md-4">
                <img src={sketch} /> Sketch
                </div>
            </div>
            </div>
        </div>

        <div className="frontend">
            <h2>Front-end <img src={line}></img></h2>
            <div className="frontendelements">
            <div className="row">
                <div className="col-md-4">
                <img src={js} /> Java Script
                </div>
                
                <div className="col-md-4">
                <img src={html} /> HTML
                </div>
                <div className="col-md-4">
                <img src={css} /> CSS
                </div>
                <div className="col-md-4">
                <img src={react} /> React
                </div>
                <div className="col-md-4">
                <img src={react_native} /> React Native
                </div>
                <div className="col-md-4">
                <img src={angular} /> Angular

                </div>
                <div className="col-md-4">
                <img src={flutter} /> Flutter
                </div>
                <div className="col-md-4">
                <img src={npm} /> NPM
                </div>
                <div className="col-md-4">
                <img src={vue} /> Vue.JS
                </div>
                <div className="col-md-4">
                <img src={iconic } /> Ionic 
                </div>
                <div className="col-md-4">
                <img src={bootstrap} /> BootStrap
                </div>
            </div>
            </div>
        </div>
        <div className="backend">
            <h2>Back-end <img src={line}></img></h2>
            <div className="backendelements">
            <div className="row">
                <div className="col-md-4">
                <img src={dotnet} /> .Net
                </div>
                
                <div className="col-md-4">
                <img src={node} /> Node JS
                </div>
                <div className="col-md-4">
                <img src={java} /> Java
                </div>
                <div className="col-md-4">
                <img src={ruby} /> Ruby On Rails
                </div>
                <div className="col-md-4">
                <img src={laravel} /> Laravel
                </div>
                <div className="col-md-4">
                <img src={php} /> PHP

                </div>
                <div className="col-md-4">
                <img src={golang} /> Golang
                </div>
                
            </div>
            </div>
        </div>

        <div className="mobile">
            <h2>Mobile <img src={line}></img></h2>
            <div className="mobileelements">
            <div className="row">
                <div className="col-md-4">
                <img src={ios} /> iOS
                </div> 
                <div className="col-md-4">
                <img src={android} /> Android
                </div>
                <div className="col-md-4">
                <img src={flutter} /> Flutter
                </div>
                <div className="col-md-4">
                <img src={react} /> React Native
                </div>
                <div className="col-md-4">
                <img src={iconic} /> Ionic
                </div>
                <div className="col-md-4">
                <img src={kotlin} /> Kotlin

                </div>
                
            </div>
            </div>
        </div>

        <div className="server">
            <h2>Server <img src={line}></img></h2>
            <div className="serverelements">
            <div className="row">
                <div className="col-md-4">
                <img src={google} /> Azure,Google Cloud
                </div>
                
                <div className="col-md-4">
                <img src={aws} /> AWS
                </div>
                <div className="col-md-4">
                <img src={digital} /> Digital Ocean
                </div>
                <div className="col-md-4">
                <img src={heroku} /> Heroku
                </div>
                
                
            </div>
            </div>
        </div>

        <div className="database">
            <h2>Database <img src={line}></img></h2>
            <div className="databaseelements">
            <div className="row">
                <div className="col-md-4">
                <img src={sql} /> MS SQL
                </div>
                
                <div className="col-md-4">
                <img src={mongo} /> MongoDB
                </div>
                <div className="col-md-4">
                <img src={postsql} /> PostgreSQL
                </div>
                <div className="col-md-4">
                <img src={dynamo} /> Dynamo DB
                </div>
                <div className="col-md-4">
                <img src={mysql} /> MySQL
                </div>
                
                
                
            </div>
            </div>
        </div>

        <div className="ecommerce">
            <h2>eCommerce <img src={line}></img></h2>
            <div className="ecommerceelements">
            <div className="row">
                <div className="col-md-4">
                <img src={bigcommerce} /> BigCommerce
                </div>
                
                <div className="col-md-4">
                <img src={magento} /> Magento
                </div>
                <div className="col-md-4">
                <img src={volusion} /> Volusion
                </div>
                <div className="col-md-4">
                <img src={salesforce} /> Salesforce 
                </div>
                <div className="col-md-4">
                <img src={woo} /> WooCommerce
                </div>
                <div className="col-md-4">
                <img src={shift} /> Shift4Shop
                </div>
                <div className="col-md-4">
                <img src={shopify} /> Shopify
                </div>
                <div className="col-md-4">
                <img src={kibo} /> Kibo
                </div>
                <div className="col-md-4">
                <img src={git} /> Git
                </div>
                
                
                
            </div>
            </div>
        </div>

        <div className="project">
            <h2>Project Management Tools <img src={line}></img></h2>
            <div className="projectelements">
            <div className="row">
                <div className="col-md-4">
                <img src={jira} /> Jira
                </div>
                
                <div className="col-md-4">
                <img src={trillo} /> Trello
                </div>
                <div className="col-md-4">
                <img src={basecamp} /> BaseCamp
                </div>
                <div className="col-md-4">
                <img src={ms} /> MS Project
                </div>
                
                
            </div>
            </div>
        </div>

        <div className="devops">
            <h2>Devops <img src={line}></img></h2>
            <div className="devopselements">
            <div className="row">
                <div className="col-md-4">
                <img src={apache} /> Apacheant
                </div>
                
                <div className="col-md-4">
                <img src={xcode} /> Xcode
                </div>
                <div className="col-md-4">
                <img src={fastlane} /> Fastlane Gant
                </div>
                <div className="col-md-4">
                <img src={gradle} /> Gradle
                </div>
                <div className="col-md-4">
                <img src={maven} /> Maven
                </div>
                <div className="col-md-4">
                <img src={anisble} /> Ansible
                </div>
                <div className="col-md-4">
                <img src={chef} /> Chef
                </div>
                <div className="col-md-4">
                <img src={puppet} /> Puppet
                </div>
                
                
                
            </div>
            </div>
        </div>

        <div className="cms">
            <h2>CMS <img src={line}></img></h2>
            <div className="cmselements">
            <div className="row">
                <div className="col-md-4">
                <img src={wordpress} /> Wordpress
                </div>
                
                <div className="col-md-4">
                <img src={drupal} /> Drupal
                </div>
                
                
                
            </div>
            </div>
        </div>

        <div className="version">
            <h2>Version Control <img src={line}></img></h2>
            <div className="versionelements">
            <div className="row">
                <div className="col-md-4">
                <img src={github} /> Github
                </div>
                
                <div className="col-md-4">
                <img src={bitbucket} /> BitBucket
                </div>
                <div className="col-md-4">
                <img src={gitlab} /> Gitlab
                </div>
                
                
                
            </div>
            </div>
        </div>

        <div className="other">
            <h2>Other <img src={line}></img></h2>
            <div className="otherelements">
            <div className="row">
                <div className="col-md-4">
                <img src={other} /> Agile TDD
                </div>  
            </div>
            </div>
        </div>

        <div className="communication">
            <h2>Communication Tools <img src={line}></img></h2>
            <div className="communicationelements">
            <div className="row">
                <div className="col-md-4">
                <img src={slack} /> Slack
                </div>  
                <div className="col-md-4">
                <img src={msteam} /> Microsoft Team
                </div>
                <div className="col-md-4">
                <img src={hangout} /> Hangout
                </div>
            </div>
            </div>
        </div>

        <div className="meeting">
            <h2>Meeting <img src={line}></img></h2>
            <div className="meetingelements">
            <div className="row">
                <div className="col-md-4">
                <img src={meet} /> Google Meet
                </div>  
                <div className="col-md-4">
                <img src={zoom} />  Zoom
                </div>

            </div>
            </div>
        </div>




      </div>
     
    );
}

export default withRouter(OurServices);