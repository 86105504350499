import { React, useState } from "react";
import { Col, Container, Row, ListGroup, Card, Nav } from "react-bootstrap";
import FooterLogo from "../../images/footer-logo.svg";
import ScrollTop from "../../images/ScrollTop.svg";

import * as Icon from "react-feather";
const FooterContact = () => {
  const [isActive, setActive] = useState("");
  const ToggleClass = (e) => {
    setActive((prev) => (prev === e ? "" : e));
  };
  const scrollTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <Container className="og-footer-contact mb-5">
      <Row xs={1} lg={3} xl={5} className="g-5">
        <Col className="img-section">
          <Card>
            <Card.Body>
              <Card.Img variant="top" src={FooterLogo} />
              <Card.Text>Your Go-To Digital Developers</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Nav>
            <ul className="">
              <li>
                <Nav.Link className="aboutusFooter" href="/">
                  Home
                </Nav.Link>
              </li>
              <li>
                <Nav.Link className="aboutusFooter" href="/aboutUs">
                  About Us
                </Nav.Link>
              </li>
              <li>
                <Nav.Link className="aboutusFooter" href="/Services">
                  Services
                </Nav.Link>
              </li>
              <li>
                <Nav.Link className="aboutusFooter" href="/approach">
                  Approach
                </Nav.Link>
              </li>
              <li>
                <Nav.Link className="aboutusFooter" href="/carrers">
                  Careers
                </Nav.Link>
              </li>
            </ul>
          </Nav>
        </Col>

        <Col className="contact-section">
          <Card>
            <Card.Body>
              <Card.Title
                onClick={() => ToggleClass("contact")}
                className={`contact-title ${
                  isActive === "contact" ? "contact-active" : ""
                }`}
              >
                Contact US -{" "}
              </Card.Title>
              <ListGroup
                className={`contact-menu ${
                  isActive === "contact" ? "contact-wrap" : ""
                }`}
              >
                <ListGroup.Item>
                  <Icon.Mail /> hello@origami.dev
                </ListGroup.Item>

                <ListGroup.Item>
                  <Icon.PhoneCall /> US +1 209 881 7224
                </ListGroup.Item>
                <ListGroup.Item>
                  <Icon.PhoneCall /> India +91 761 878 0865
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  <Icon.PhoneCall /> IN +91 761 878 0865
                </ListGroup.Item> */}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col className="off-section">
          <Card>
            <Card.Body>
              <Card.Title
                onClick={() => ToggleClass("Office")}
                className={`contact-title ${
                  isActive === "Office" ? "contact-active" : ""
                }`}
              ></Card.Title>
              <h4>Newyork</h4>

              <p>
                1180 Avenue of Americas <br />
                New York <br />
                NY, 10036, US
              </p>

              <h4>Bangalore</h4>

              <p>
                Hustlehub Tech Park, Somasundara palya Main Road, 27th Main
                Road, Sector 2, HSR Layout, Bengaluru, Karnatak-560102
              </p>
              {/* <ListGroup
                className={`contact-menu ${
                  isActive === "Office" ? "contact-wrap" : ""
                }`}
              >
                <ListGroup.Item>
                  1180 Avenue of Americas New York, NY, 10036
                </ListGroup.Item>
              </ListGroup> */}
            </Card.Body>
          </Card>
        </Col>
        <Col className="upArrowRed">
          <img src={ScrollTop} onClick={() => scrollTop()} />
        </Col>
      </Row>
    </Container>
  );
};

export default FooterContact;
