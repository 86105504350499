import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from "react-router";
import icon1 from "../../images/user/icon1.svg"
import icon2 from "../../images/user/icon2.svg"
import icon3 from "../../images/user/icon3.svg" 
import ux from "../../images/user/ux.png"
import headerimg from "../../images/user/headerimg.jpg" 
import './style.css';
import { Button } from "react-bootstrap";

import GetRequirementForm from "../../components/GetRequirementForm";

const userExperienceDesign = ()=>{
    return(
        <div className="content">
        <div className="userheader" >
        <img src={headerimg}></img>
        <div className="usercentered">
           <h1>User Experience Design Agency</h1>
           <h3>Build a consistent user experience design that delivers!

        </h3>
            <h5>We offer client-focused UX design services to create modern yet engaging digital experiences.</h5>
           <center> <Button><a href="/getInTouch">Get a free quote</a> </Button></center>
        </div>
      </div>
      <div className="development">
      <h1>Our User Experience Design Process</h1>
      <p>At Origami, we follow a proven UX design process to create user-centered,
intuitive, and compelling designs for both web and mobile</p>
      <div className="process" >
      <div className="row">
        
      <div className="benefitsCol col-md-4">
          <div className="benefitsCardBox card">
              <div className="card-body">
              <img src={icon3}></img>
                      <div className="benefitsCardTitleText card-title h5">Research</div>
                      <p className="cardTextBenefits card-text">We develop a strategy that supports the needs of your business users. Once we learn who your target audience is, we try to understand users’ thought processes to identify their mental models. And, accordingly, we pass on the information to our designing team to start working on it.


                       </p>
                       </div>
                       </div>
                       </div>
                       <div className="benefitsCol col-md-4">
          <div className="benefitsCardBox card">
              <div className="card-body">
              <img src={icon2}></img>
                      <div className="benefitsCardTitleText card-title h5">Wireframe</div>
                      <p className="cardTextBenefits card-text">Our expert UI/UX designers provide a visual presentation of your product features and flow by creating wireframes. Doing so can help us discover the opportunities for making the app faster and efficient for users so that they can navigate hassle-free and focus on the task while using your product.

                       </p>
                       </div>
                       </div>
                       </div>
                       <div className="benefitsCol col-md-4">
          <div className="benefitsCardBox card">
              <div className="card-body">
              <img src={icon1}></img>
                      <div className="benefitsCardTitleText card-title h5">User interface</div>
                      <p className="cardTextBenefits card-text">Once your app is deployed and need the optimisation of an e-commerce stIn this phase, we design your product visually appealing and user-friendly to represent your brand as the best amongst your competitors. Our experts employ user-centric design to make your product look with a focus on ease of use.


                       </p>
                       </div>
                       </div>
                       </div>
                       </div> 
      </div>
      </div>
      
      <div className="marketplacecontent">
      <div className="usermarketplace">
      {/* <img src={ux}></img> */}
          <div className="usertextblock">
              <h3>Vocal Corner Store</h3>
              <p>Vocal Corner Store is a full service Artist Development
                   & Digital Agency. <br/>Catering to everyone, from Emerging Artists to Established Stars 

</p>
<Button><a href="https://vcs.la/">View Project</a></Button>
          </div>
</div>
</div>
<div className="development">
      <h1>User Experience Design Services</h1>
      <p>We provide result-oriented UX design services to craft interactive experiences
that support both your business goals and users needs.

           
</p>
      <div className="process" >
      <div className="row">
        
      <div className="benefitsCol col-md-4">
          <div className="benefitsCardBox card">
              <div className="card-body">
              
                      <div className="benefitsCardTitleText card-title h5">USER RESEARCH

</div>
                      <p className="cardTextBenefits card-text">At Origami, our UX designers have in-depth knowledge and a better understanding of the product and its end-users needs. Our experts make a user journey map to inform the initial wireframe and deliver the best results in the research phase. Hire our user experience expert to do better than your competition.
                       </p>
                       </div>
                       </div>
                       </div>
                       <div className="benefitsCol col-md-4">
          <div className="benefitsCardBox card">
              <div className="card-body">
              
                      <div className="benefitsCardTitleText card-title h5">USER INTERFACE DESIGN</div>
                      <p className="cardTextBenefits card-text">With proven expertise in delivering best-in-class user experience, our expert UX designers create light user interface design so that the design elements such as color, texture, shape, images, composition, etc., in your product support the underlying user experience with low-fidelity to high-fidelity designs.



                       </p>
                       </div>
                       </div>
                       </div>
                       <div className="benefitsCol col-md-4">
          <div className="benefitsCardBox card">
              <div className="card-body">
             
                      <div className="benefitsCardTitleText card-title h5">USER EXPERIENCE 
STRATEGY

</div>
                      <p className="cardTextBenefits card-text">We create a mind-blowing strategy for your digital product aligned with your business capabilities and customer’s point of view on the user experience. We use many creative methods and strategies to understand the customers’ habits, needs, and challenges for crafting a seamless digital experience.



                       </p>
                       </div>
                       </div>
                       </div>
                       <div className="benefitsCol col-md-4">
          <div className="benefitsCardBox card">
              <div className="card-body">
             
                      <div className="benefitsCardTitleText card-title h5">WIREFRAME & 
PROTOTYPING
</div>
                      <p className="cardTextBenefits card-text">Our UX designers help you create product wireframes, mockups, and prototypes according to user preferences by getting early feedback. It allows them to take development decision quickly in making one-of-a-kind design that remains futuristic.



                       </p>
                       </div>
                       </div>
                       </div>
                       <div className="benefitsCol col-md-4">
          <div className="benefitsCardBox card">
              <div className="card-body">
             
                      <div className="benefitsCardTitleText card-title h5">DESIGN SPRINTS
</div>
                      <p className="cardTextBenefits card-text">We use the latest tools and technologies to build and test a prototype that satisfies users’ needs and helps organisations achieve objectives. Our user experience team understands how your product users see the product and have expertise to deliver all possible solutions to user problems.



                       </p>
                       </div>
                       </div>
                       </div>
                       <div className="benefitsCol col-md-4">
          <div className="benefitsCardBox card">
              <div className="card-body">
             
                      <div className="benefitsCardTitleText card-title h5">USABILITY TESTING
</div>
                      <p className="cardTextBenefits card-text">Leverage our expertise to improve UX through usability testing and honest feedback. We are well-versed in meeting the demands of your users with excellent and innovative designs. Our goal is to eliminate the user flow silos so that your users can get the seamless experience.


                       </p>
                       </div>
                       </div>
                       </div>
                       </div> 
      </div>
      </div>
<div className="getintouch">
    <div className="row">
        <div className="details1 col-md-4">
        <h6>Get in touch</h6>
        <p>with us to know more</p>
        </div>
        <div className="details2 col-md-8">
        <h6>Origami is a leading user experience design agency that adds value to your digital products.
</h6>
        </div>
    </div>

</div>
<GetRequirementForm 
    title ="Got a project in mind?"
/>

      </div>
     
    );
}

export default withRouter(userExperienceDesign);