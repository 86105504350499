import React from "react";
import { Container, Navbar, Nav, Button, Row, Col } from "react-bootstrap";
import Logo from "../../images/logo.svg";
import * as Icon from "react-feather";

const DesktopMenu = (props) => {
  const {gotoPage} = props;
  console.log("props", props);

  console.log("window.location.pathname", window.location.pathname);
  const currentUrl = window?.location?.pathname;
  return (
    <>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav>
          <Nav.Link className={currentUrl ==="/" ? "menu-item menu-selected-item"  :  "menu-item"} onClick={()=>{gotoPage("/")}}>
            HOME
          </Nav.Link>
          <Nav.Link className={currentUrl ==="/aboutUs" ? "menu-item menu-selected-item"  :  "menu-item"}   onClick={()=>{gotoPage("/aboutUs")}} >
            ABOUT US
          </Nav.Link>
          <Nav.Link className={currentUrl ==="/class2Mobile" ? "menu-item menu-selected-item"  :  "menu-item"}   onClick={()=>{gotoPage("/class2Mobile")}} >
          CLASS II & CLASS III GAMES
          </Nav.Link>
          <Nav.Link className={currentUrl ==="/Services" ? "menu-item menu-selected-item"  :  "menu-item"}   onClick={()=>{gotoPage("/Services")}}>
            SERVICES
          </Nav.Link>
          <Nav.Link className={currentUrl ==="/approach" ? "menu-item menu-selected-item"  :  "menu-item"}  onClick={()=>{gotoPage("/approach")}} >
            APPROACH
          </Nav.Link>
          <Nav.Link className={currentUrl ==="/carrers" ? "menu-item menu-selected-item"  :  "menu-item"}  onClick={()=>{gotoPage("/carrers")}} >
            CAREERS
          </Nav.Link>
        </Nav>
        <Button> <a onClick={()=>{gotoPage("/getInTouch")}}> LET’S CHAT</a></Button>
      </Navbar.Collapse>
    </>
  );
};

export default DesktopMenu;
