import React from "react";
import { Container, Navbar } from "react-bootstrap";
import "./Header.scss";
import "../NavigationMenu/menu.scss";
import Logo from "../../images/logo.svg";
import MobileMenu from "../NavigationMenu";
import DesktopMenu from "../NavigationMenu/desktop";
import {
  useLocation,
  useHistory,
} from "react-router-dom";

import { useMediaQuery } from "react-responsive";

const Header = (props) => {
  console.log("props", props)
  const history = useHistory();
  const isTablet = useMediaQuery({ maxWidth: 992 });
  const gotoPage = (url)=>{
    history.push(url)
    window.scrollTo(0, 0);
  }
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  // const isDesktop = useMediaQuery({ minWidth: 992 });
  return (
    <header className="main-header">
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
      >
        <Container>
          <Navbar.Brand onClick={()=>{gotoPage("/")}}>
            <img
              src={Logo}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          {isTablet ? <MobileMenu gotoPage={gotoPage}/> : <DesktopMenu gotoPage={gotoPage}/>}
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
