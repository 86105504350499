import React from "react";


const NotFound = () => {

     return (
        <div>
        <h3>
            No match
        </h3>
        </div>
    );

}

export default NotFound