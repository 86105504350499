import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router";
import icon1 from "../../images/classGames/vector-icon.svg";
import icon2 from "../../images/classGames/vector-icon1.svg";
import icon3 from "../../images/hire/icon3.svg";
import image from "../../images/block/image.png";
import HeroImage from "../../images/classGames/Hero-Image.png";
import "./style.css";
import { Button } from "react-bootstrap";
import GetRequirementForm from "../../components/GetRequirementForm";

const ClassGames = () => {
  return (
    <div className="content">
      <div className="blockheader">
        <img src={HeroImage}></img>
        <div className="blockcentered-games">
          <h1>
            We have created the first mobile Class II games in the country
          </h1>
          {/* <h3>Custom Blockchain Solutions in Focus </h3> */}
          <h5>
            Now is the time. With the recent landmark decision by the NIGC
            expanding the Class II classification to include mobile gaming, plus
            the failure of propositions in California and elsewhere to expand
            tribal gaming, we wholeheartedly believe the future for our tribal
            nations is Class II.
          </h5>
          <center>
            <Button>
              <a href="/getInTouch">Get a free quote</a>{" "}
            </Button>
          </center>
        </div>
      </div>
      <div className="questions-class-sec process">
        <div className="sec-ques-top">
          <h3>What is class II? And why is it important?</h3>
          <p>
            Class II gaming includes bingo and non-bingo games. Tribes do not
            need to enter into any agreements at the state level to conduct
            Class II gaming under IGRA. Instead, tribal governments are
            responsible for regulating Class II gaming with NIGC oversight.
          </p>
          <p>
            This is critically important to our tribal partners because it
            minimizes both State and Federal intervention so that tribes can
            reap the benefits without having to give a large cut to the State
            governments, often driven by greedy politicians looking to close
            gaps in their yearly budgets by increasing taxes on tribal gaming
            profits.
          </p>
        </div>
        <div className="sec-ques-top">
          <h3>Why Origami?</h3>
          <p>
            Because we’ve been in the game since Day One. Designing a game to
            meet the Class II requirement is extremely difficult. Most of the
            major non-tribal operators have had interest in but failed to
            execute on Class II gaming. It is complex, time-consuming, and the
            regulatory requirements can be an insurmountable obstacle. And we’ve
            done it.
          </p>
          <p className="red-quates">
            {" "}
            <img src={icon2}></img> We have created the first mobile Class II
            games in the country. No one else can say this.{" "}
            <img src={icon1}></img>{" "}
          </p>
          <p>
            Origami is not only the premier game design lab in the United States
            specializing in Class II and Class III mobile games but was
            instrumental in creating the first Class II mobile sports game in
            the country. We have knowledge and experience that is unparalleled
            in the Class II space. Furthermore, we have relationships with the
            top game testing labs in order to foster new games through
            certification in a fraction of the time normally required.
          </p>
          <p>
            {" "}
            We strongly believe in the independence and sovereignty of all
            tribal nations and work have worked with tribes to get through the
            hurdles of government regulations and oversight, designing and
            deploying mobile gaming solutions in record time.
          </p>
        </div>
      </div>
      <div className="marketplacecontent">
        <div className="marketplace1games">
          {/* <img src={image}></img> */}
          <div className="chaintextblock1-games">
            <h3>Playsqor</h3>
            <p>
              Playsqor is a U.S. based company that offers a 100% legal Sports
              Matchup Game. It was created by some of the top talents in sports
              gaming in conjunction with leading U.S
            </p>
            <Button>
              <a target="_blank" href="https://vetnos.co/">
                VIEW PROJECT
              </a>
            </Button>
          </div>
        </div>
      </div>
      <div className="questions-class-sec process">
        <div className="sec-ques-top">
          <h3>We also do Class III.</h3>
          <p>
            We also design and create Class III games and although C3 games are
            taxed by States, the definition is large enough so that a large
            array of games can be included under the Class III classification.
            We build these too.
          </p>
        </div>
      </div>

      <div className="getintouch">
        <div className="row">
          <div className="details1 col-md-4">
            <h6>Get in touch</h6>
            <p>with us to know more</p>
          </div>
          <div className="details2 col-md-8">
            <h6>
              Do you have an app idea? We have highly competent developers that
              will help you build industry-specific Gaming apps, leveraging a
              wide range of tools and technologies to scale your business
              operations.
            </h6>
          </div>
        </div>
      </div>
      <GetRequirementForm title="Let’s build your app that never fails in delighting your customer!" />
    </div>
  );
};

export default withRouter(ClassGames);
