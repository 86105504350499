import React from "react";
import { Card, Button, Row ,Col } from "react-bootstrap";

const FooterContent = () => {
  return (


    <Card className="og-footer-getintouch">
      <Card.Body>
        <Row>
        <Col md={8}>
        <Card.Subtitle>Are you ready?</Card.Subtitle>
        <Card.Text>
          Let's build your next Digital product !
        </Card.Text>
        </Col>
        <Col md={4}>
          <Button className='getIntouchBtn' variant="primary"><a href="/getInTouch">Get in touch</a></Button>
        </Col>
       </Row>
        
      </Card.Body>
    </Card>
  );
};

export default FooterContent;
