import React, { useState } from "react";
import "./menu.scss";
import menuJson from "./menu.json";

const HamMenu = ({gotoPage}) => {
  const [openMenu, setOpenMenu] = useState();
  const [selectedMenu, setSelectedMenu] = useState(-1);
  const [selectedSubMenu, setSelectedSubMenu] = useState(-1);

  const menuClick = (index) => {
    setSelectedMenu(index);
    setSelectedSubMenu(-1);
  };

  const subMenuClick = (index) => {
    setSelectedSubMenu((prevState) => {
      return prevState === -1 ? index : -1;
    });
  };

  const onMenuBackClick = () => {
    setSelectedMenu(-1);
    setSelectedSubMenu(-1);
  };

  const navBarClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className="mo-toggle-menu-mobile">
      <button class={`humberger ${openMenu ? "active-menu":""}`} onClick={navBarClick}>
				<span class="line line-1">&nbsp;</span>
				<span class="line line-2">&nbsp;</span>
				<span class="line line-3">&nbsp;</span>
			</button>

      <ul
        className={`main-menu link-effect d-lg-flex ${
          openMenu ? "first-open" : ""
        }`}
      >
        {menuJson.map((menu, i) => (
          <li className={`overlay-open`} onClick={()=>{
            gotoPage(menu.link);
            navBarClick();
            }}>
            <span
              className="click-arrow link-event"
            >
              {menu.label}
            </span>
            
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HamMenu;
