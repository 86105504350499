import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router";
import about from "../../images/services/about.png";
import line from "../../images/services/line.png";
import "./style.css";
import { Button } from "react-bootstrap";

const aboutUs = () => {
  return (
    <div className="content">
      <div className="about">
        <div className="row">
          <div className="aboutnew1 col-md-6">
            <div className="row">
              <h4>
                ABOUT US <img src={line}></img>
              </h4>
              <h1>
                Here’s where <span>Awesome things</span> are made!
              </h1>
              <p>
                We are a team of{" "}
                <span>
                  {" "}
                  artists, engineers, practitioners, executives, and tacticians{" "}
                </span>
                with roots stretching back to the earliest days of web
                applications. We are passionate about providing unique solutions
                to client challenging initiatives. We believe by taking a
                holistic approach to collaborative solution design our clients
                will be empowered to accelerate their growth and create a true
                competitive edge in their market.
              </p>
              <Button>
                <a href="/getInTouch">JOIN US</a>
              </Button>
            </div>
          </div>
          <div className="aboutnew2 col-md-6">
            <img src={about}></img>
          </div>
        </div>
      </div>

      <div className="mission">
        <div className="row">
          <div className="missioncontent1 col-md-6">
            <h5>
              Our Mission <img src={line}></img>
            </h5>
            <h2>
              <span>Accelerate</span> the World's Innovation
            </h2>
          </div>
          <div className="missioncontent2 col-md-6">
            <p>
              Upholding the principles of service delivery, we tender
              client-centric solutions that bring them maximum productivity in
              the least resources. As our motto says ‘Ideate, Innovate, Create’,
              we aspire to bring in result-oriented strategies to carve the
              strategies for product design and creation.
            </p>
          </div>
        </div>
      </div>
      <div className="vision">
        <div className="row">
          <div className="visioncontent1 col-md-6">
            <h5>
              Our Vision <img src={line}></img>
            </h5>
            <h2>
              Deliver IT <span>Perfection</span>
            </h2>
          </div>
          <div className="visioncontent2 col-md-6">
            <p>
              We aspire to set the benchmark for excellence and trust , by
              delivering quality IT services and high business value to our
              clients. Origami aims to be an Organization of choice for talent
              and clients alike.
            </p>
          </div>
        </div>
      </div>
      <div className="values">
        <div className="row">
          <div className="valuescontent1 col-md-6">
            <h5>
              Our Values <img src={line}></img>
            </h5>
            <h2>
              Credibility. <span>Quality.</span> Sustainability.{" "}
            </h2>
          </div>
          <div className="valuescontent2 col-md-6">
            <p>
              Ensuring committed deliveries of all projects within dedicated
              timelines. Our primary goal while serving our customers is to
              create value in terms of more revenues and strengthened
              technological backings. As a leading service provider, we have
              attempted to create sustainable solutions on the grounds of
              credibility, quality, and a high-powered delivery approach.
            </p>
          </div>
        </div>
      </div>
      <div className="location">
        <h2>Locations</h2>
        {/* <h3>Corporate Headquarters</h3> */}
        <div className="row">
          <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox1 card">
              <div className="card-body">
                <div className="place1">
                  <h4>Newyork</h4>

                  <p>
                    1180 Avenue of Americas <br />
                    New York <br />
                    NY, 10036, US
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox1 card">
              <div className="card-body">
                <div className="place1">
                  <h4>Bangalore</h4>

                  <p>
                    Hustlehub Tech Park, Somasundara palya Main Road, 27th Main
                    Road, Sector 2, HSR Layout, Bengaluru, Karnatak-560102
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h3>Delivery Centers</h3>
        <div className="row">
          <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox1 card">
              <div className="card-body">
                <div className="place1">
                  <h4>Hyderabad</h4>

                  <p>
                    255, Botanical Garden Road,
                    <br /> Kondapur, Hyderabad,
                    <br /> Telangana -500084
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox1 card">
              <div className="card-body">
                <div className="place1">
                  <h4>Bangalore</h4>

                  <p>
                    Hustlehub Tech Park, Somasundara palya Main Road, 27th Main
                    Road, Sector 2, HSR Layout, Bengaluru, Karnatak-560102
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="benefitsCol col-md-4">
            <div className="benefitsCardBox1 card">
              <div className="card-body">
                <div className="place1">
                  <h4>Chennai</h4>

                  <p>
                    #10, Uthamar Gandhi Salai, Nungambakkam,
                    <br /> Chennai -600034
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default withRouter(aboutUs);
