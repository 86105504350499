import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from "react-router";
import icon1 from "../../images/hire/icon1.svg"
import icon2 from "../../images/hire/icon2.svg"
import icon3 from "../../images/hire/icon3.svg"
import image from "../../images/block/image.png"
import headerimg from "../../images/block/headerimg.jpg" 
import './style.css';
import { Button } from "react-bootstrap";
import GetRequirementForm from "../../components/GetRequirementForm";


const blockChainDevelopment = () => {
    return (
        <div className="content">
            <div className="blockheader" >
                <img src={headerimg}></img>
                <div className="blockcentered">
                    <h1>Blockchain Software Development</h1>
                    <h3>Custom Blockchain Solutions in Focus
        </h3>
                    <h5>Our blockchain development team is skilled enough to leverage Hyperledger technology  <br /> to
                 build a secure corporate blockchain for your business and keep multi-party <br /> engagements
                 immutable and tamperproof.</h5>
                    <center><Button><a href="/getInTouch">Get a free quote</a> </Button></center>
                </div>
            </div>
            <div className="development">
                <h1>Industry Experience</h1>
                <p>Our skills in blockchain software development have been<br /> instrumental in a wide array of industries:</p>
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon1}></img>
                                    <div className="benefitsCardTitleText card-title h5">Healthcare</div>
                                    <p className="cardTextBenefits card-text">Our team of experts creates blockchain-driven solutions for health data acquisition and management, systems for medical workflow management, and secure decentralized databases for storing health data.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon3}></img>
                                    <div className="benefitsCardTitleText card-title h5">Transportation</div>
                                    <p className="cardTextBenefits card-text">Through combining blockchain application development with smart contracts and IoT sensors, we enable transportation companies to improve their logistics processes and supply chain transparency — tracking goods and materials throughout the entire delivery process.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon2}></img>
                                    <div className="benefitsCardTitleText card-title h5">Collectibles as NFT</div>
                                    <p className="cardTextBenefits card-text">We help artists & digital content creators to create non-fungible tokens for their artworks, paintings, or drawings. The creators can auction the artworks in an NFT marketplace, where sellers can bid and purchase the items. Once the auction closes, smart contracts transfer the ownership of the item to the buyer.

                       </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> 

            <div className="marketplacecontent">
                <div className="marketplace1">
                    {/* <img src={image}></img> */}
                    <div className="chaintextblock1">
                        <h3>Cybertino</h3>
                        <p>Cybertino is the world's first interactive NFT platform for influencers and fans.<br />
                   In short-term, it's aiming to build the first NFT platform that enables ...
</p>
                        <Button><a href="https://cybertino.com/">View Project</a></Button>
                    </div>
                </div>
            </div>
            <div className="development">
                <h1>What We Build</h1>
                {/* <p>Leverage the expertise of our dedicated development team and experience <br/>best-in-class product
       development.We provide a wide range of services to <br/>transform your potential business idea into a
        viable solution
           
</p> */}
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">Smart Contracts: Writing and Testing
</div>
                                    <p className="cardTextBenefits card-text">The Origami team writes all types of smart contracts,
                                    including Ethereum, Hyperledger, EOS, and NEO-based. To make sure there are no flaws,
                                    misbehaviors and security holes in the smart contract code, we perform full-scale
                                    pre-release functional and non-functional testing.
                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">Technical Development for ICO</div>
                                    <p className="cardTextBenefits card-text">If you have a business idea and need a platform
                                    for project monetization, our team is well-versed in delivering Initial Coin Offerings (ICO).
                                    We will provide an accurate technical realization of your ICO ecosystem: develop an
                                    ERC20-compliant token, build your wallet and oracles, and review the technical and
                                    QA parts of your Whitepaper.



                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">Decentralized Apps (Dapps)</div>
                                    <p className="cardTextBenefits card-text">Dapps combine a number of interrelated smart
                                    contracts, united by a business logic and frontend. These apps help customers enable
                                    faster payment processing, and provide secure and reliable data records. Our team builds
                                    up a complete Dapp architecture, including communication with oracles, and creates a
                                    comprehensive UX/UI design.


                       </p>
                                </div>
                            </div>
                        </div>

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">Blockchain Ecosystems
</div>
                                    <p className="cardTextBenefits card-text">Blockchain ecosystems span a full range of
                                    cryptocurrency trading apps allowing for transparent and immutable transactions. Origami
                                    crafts any blockchain ecosystem element from scratch including crypto wallets, analytics tools,
                                    multiplatform exchanges, blockchain explorers, oracle applications, and portfolio monitor
                                    solutions.



                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">NFT Development
</div>
                                    <p className="cardTextBenefits card-text">Our programmers have expertise in Etereum NFT, such as ERC-721. We can make tokens for physical assets (real estate, art objects), virtual assets (unique items of the game world), and, of course, for negative value assets (debts, bonds, liabilities). Of the popular ERC-721, we also know how to work with other NFT standards: ERC-1155, ERC-998, and ERC-875.



                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">NFT Wallet Development
</div>
                                    <p className="cardTextBenefits card-text">We develop and deliver a customized NFT wallet specifically designed to store your non-fungible tokens virtually. With a feature-loaded, dedicated wallet for NFTs, you get the ability to store and transfer your non-fungible tokens seamlessly. Our experts also provide NFT wallet integration services
                                    white_check_mark
                                    eyes
                                    raised_hands





                                    6:27
                                    Collectibles as NFT



                       </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="getintouch">
                <div className="row">
                    <div className="details1 col-md-4">
                        <h6>Get in touch</h6>
                        <p>with us to know more</p>
                    </div>
                    <div className="details2 col-md-8">
                        <h6>Extend your team with our skilled, experienced, and motivated programmers who will work for you just like your in-house team to address your development challenges.
</h6>
                    </div>
                </div>

            </div>
            <GetRequirementForm 
            title = "Let’s build your app that never fails in delighting your customer!"
            />


        </div>

    );
}

export default withRouter(blockChainDevelopment);