import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from "react-router";
import blue from "../../images/approach/blue.png"
import purple from "../../images/approach/purple.png"
import pink from "../../images/approach/pink.png"
import green from "../../images/approach/green.png" 
import semiblue from "../../images/approach/semiblue.png" 
import semipurple from "../../images/approach/semipurple.png" 
import semipink from "../../images/approach/semipink.png" 
import semigreen from "../../images/approach/semigreen.png" 

import './style.css';



const approach = ()=>{
    return(
        <div className="content">
        
<div className="approach"> 
    <div className="row">
        <div className="approachnew1 ">
        
            <h3>Creating experience based on people.</h3>
           <p>We combine thorough research with years of experience to design and 
               deliver scalable <br/> products and help you implement them along the way.</p>
           <div className="approachheader" >
           <div className="row">
            <div className="col-md-3">
            <p>PEOPLE</p>
            <p>STRATEGY</p>
            <p>INTERACTION</p>
            <p>TECHNOLOGY</p>
            <div className="imagecontent">
            <img src={blue}></img>
            <div className="center"><b>Explore</b></div>
            </div>
            </div>
            <div className="col-md-3">
            <p>CUSTOMER</p>
            <p>BUSINESS</p>
            <p>OBJECTIVES</p>
            <p>INSIGHT</p>
            <div className="imagecontent">
            <img src={purple}></img>
            <div className="center"><b>Define</b></div>
            </div>
            </div>
            <div className="col-md-3">
            <p>HYPOTHESIS</p>
            <p>DESIGN</p>
            <p>TEST</p>
            <p>EVALUATE</p>
            <div className="imagecontent">
            <img src={pink}></img>
            <div className="center"><b>Ideate</b></div>
            </div>
            </div>
            <div className="col-md-3">
            <p>GUIDE</p>
            <p>IMPLEMENT</p>
            <p>SUPPORT</p>
            <p>HELP</p>
            <div className="imagecontent">
            <img src={green}></img>
            <div className="center"><b>Transform</b></div>
            </div>
            </div>
           
            
           </div>
           </div>
        
         </div>
                 
         
    </div>

</div>

<div className="approach1">
    <div className="row">
        <div className="approachsection1 col-md-6">
        
           <h3>Explore</h3>
           <h5>Identifying and understanding your customers and business.</h5>
           <p>Conduct interviews to profile your customer's buyer personas Find out how your customer's 
               are using your services currently Identify opportunities of delighting your customers with
               current services Strategize ways of introducing new products to your target audience
</p>
           <div className="approachheader1" >
           <div className="row">
            <div className="col-md-3">
            <p>PEOPLE</p>
            </div>
            <div className="col-md-3">
            <p>STRATEGY</p> 
            </div>
            <div className="col-md-3">
            <p>INTERACTION </p>
            </div>
            <div className="col-md-3">
            <p>TECHNOLOGY </p> 
            </div>

           </div>
           </div>
        
         </div>
                 
         <div className="approachsection2 col-md-6">
         <img src={semiblue}></img>
         </div>
    </div>

</div>
<div className="approach2">
    <div className="row">
    <div className="approachsection3 col-md-6">
         <img src={semipurple}></img>
         </div>
        <div className="approachsection4 col-md-6">
        
           <h3>Define</h3>
           <h5>Creating a plan to design engaging, authentic experiences.</h5>
           <p>Create a map that outlines the research and design activities to be undertaken Align research 
               and implementation to your business’ objectives Prioritize design and implementation based on
                those objectives Ensure each stakeholder is aligned with the project goals before continuing.
        </p>
           <div className="approachheader2" >
           <div className="row">
            <div className="col-md-3">
            <p>CUSTOMER</p>
            </div>
            <div className="col-md-3">
            <p>BUSINESS</p> 
            </div>
            <div className="col-md-3">
            <p>OBJECTIVES </p>
            </div>
            <div className="col-md-3">
            <p>INSIGHT </p> 
            </div>
             
           </div>
           </div>
        
         </div>
                 
         
    </div>

</div>
<div className="approach3">
    <div className="row">
        <div className="approachsection1 col-md-6">
        
           <h3>Ideate</h3>
           <h5>Designing solutions that exceed expectations.</h5>
           <p>Conduct interviews to profile your customer's buyer personas Find out how your customer's 
               are using your services currently Identify opportunities of delighting your customers with
                current services Strategize ways of introducing new products to your target audience

            
          </p>
           <div className="approachheader1" >
           <div className="row">
            <div className="col-md-3">
            <p>HYPOTHESIS</p>
            </div>
            <div className="col-md-3">
            <p>DESIGN</p> 
            </div>
            <div className="col-md-3">
            <p>TEST</p> 
            </div>
            <div className="col-md-3">
            <p>EVALUATE</p> 
            </div>
            

           </div>
           </div>
        
         </div>
                 
         <div className="approachsection2 col-md-6">
         <img src={semipink}></img>
         </div>
    </div>

</div>

<div className="approach4">
    <div className="row">
    <div className="approachsection3 col-md-6">
         <img src={semigreen}></img>
         </div>
        <div className="approachsection4 col-md-6">
        
           <h3>Transform.</h3>
           <h5>We’re in this with you, every step of the way.</h5>
           <p>Turn ideas into reality by implementing the designs and technical solutions Support you through
                the process implementation Share insights and road maps with the wider business Record an 
                experience strategy for your business
          
        </p>
           <div className="approachheader2" >
           <div className="row">
            <div className="col-md-3">
            <p>GUIDE</p>
            </div>
            <div className="col-md-3">
            <p>IMPLEMENT</p> 
            </div>
            <div className="col-md-3">
            <p>SUPPORT</p> 
            </div>
            
             
           </div>
           </div>
        
         </div>
                 
         
    </div>

</div>
       



      </div>
     
    );
}

export default withRouter(approach);