import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from "react-router";
import header from "../../images/services/header.png"
import icon1 from "../../images/hire/icon1.svg"
import icon2 from "../../images/hire/icon2.svg"
import icon3 from "../../images/hire/icon3.svg"
import hire from "../../images/hire/hire.png" 
import './style.css';
import { Button } from "react-bootstrap";

import GetRequirementForm from "../../components/GetRequirementForm";

const hireDedicatedDeveloper = () => {
    return (
        <div className="content">
            <div className="hireheader" > 
                <img src={header}></img>
                <div className="hirecentered">
                    <h1 className="top">Hire Dedicated Developers</h1>
                    <h3>We provide a guarantee for the successful delivery of your project with an exclusive touch!

        </h3>
                    <h5>Our dedicated development team has in-depth knowledge and years<br /> of experience working on various
                 technologies, platforms, and <br />frameworks to deliver absolute solutions for clients worldwide.</h5>
                  <center>  <Button><a href="/getInTouch">Get a free quote </a></Button></center>
                </div>
            </div> 
            <div className="development">
                <h1>Process to hire dedicated developers</h1>
                <p>We have a smooth hiring process that will help you decide how to hire our dedicated resources<br />
       for your dream project.Our step-by-step process involves everything right from gathering<br />
 requirements to successful project delivery. Take a look!</p>
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon1}></img>
                                    <div className="benefitsCardTitleText card-title h5"> Origami managed Team</div>
                                    <p className="cardTextBenefits card-text">Be it an app development from scratch,
                                    fine-tuning the existing one, or integrating the 3rd party tool to enhance the
                                    performance, our team of certified and experienced developers take complete
                                    responsibility from pre to post-deployment and helps you grow your business.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon2}></img>
                                    <div className="benefitsCardTitleText card-title h5">Client Managed Team</div>
                                    <p className="cardTextBenefits card-text">Hire dedicated developers from Origami
                                    and have them work with your in-house development team. This team of developers
                                    will work as an extension to your team who will only be working on the task assigned
                                    by your project manager.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon3}></img>
                                    <div className="benefitsCardTitleText card-title h5">Hybrid Model</div>
                                    <p className="cardTextBenefits card-text">In this model, the hire of dedicated
                                    developers can be placed as you need. It is quite a flexible hiring model where
                                    you can deploy 50% of your developers on-site within your premises and the remaining
                                    50% will work from an offshore location or the company’s premises from where you hire them.

                       </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="marketplacecontent">
                <div className="hiremarketplace">
                    {/* <img src={hire}></img> */}
                    <div className="hiretextblock"> 
                        <h3>NameCoach</h3>
                        <p>NameCoach stands against racial violence and injustice.
                   Access public audio<br /> resources to help raise awareness.
</p>
                        <Button><a href="https://cloud.name-coach.com/">View Project</a></Button>
                    </div>
                </div>
            </div>
            <div className="development">
                <h1>Services we offer</h1>
                <p>Leverage the expertise of our dedicated development team and experience <br />best-in-class product
       development.We provide a wide range of services to <br />transform your potential business idea into a
        viable solution

</p>
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">MOBILE APPLICATION
</div>
                                    <p className="cardTextBenefits card-text">Hire experienced, dedicated, and full-stack mobile
                                    app developers from us who all have top-of-the-line-expertise in providing high-quality
                                    mobile app development services to turn your mobile app idea into reality across multiple
                                    platforms like Android, iOS, etc.
                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">WEB APPLICATION</div>
                                    <p className="cardTextBenefits card-text">Hire a team of dedicated web developers that
                                    leverage massive technical expertise to deliver the best user experience and custom
                                    functionality using technologies like Java,  .NET, Node JS Python, and CMS including
                                    Magento and WordPress.



                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">FRONT END</div>
                                    <p className="cardTextBenefits card-text">our expert front-end programmers specialize in Angular, ReactJs, ExpressJS, Mocha, and more. Our developers follow strict guidelines to maintain code quality and craft user-friendly UI, capable of breathing functionality, navigation, accessibility, and performance into your web project and making it consistent across all browsers and devices.


                       </p>
                                </div>
                            </div>
                        </div>

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">E-COMMERCE
</div>
                                    <p className="cardTextBenefits card-text">Build your customized eCommerce portal, mobile app, or website, leveraging the expertise of our dedicated programmers. Our eCommerce developers have a vast experience in Magento, Shopify, and Woo-commerce to help you rolling your business to the next level.



                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">ENTERPRISE
</div>
                                    <p className="cardTextBenefits card-text">Right from simple to complex enterprise
                                    application development, our seasoned developers have helped clients bringing their
                                    ideas to life by developing an enterprise-grade application cost-effectively within a
                                    given period of time.



                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">CMS
</div>
                                    <p className="cardTextBenefits card-text">Enhance your business efficiency with our extensive pool of hand-picked CMS developers. We have talented and knowledgeable Drupal and Word Press developers who can help you with an existing CMS to deploy a fast, responsive, and high-quality website.


                       </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="getintouch">
                <div className="row">
                    <div className="details1 col-md-4">
                        <h6>Get in touch</h6>
                        <p>with us to know more</p>
                    </div>
                    <div className="details2 col-md-8">
                        <h6>Extend your team with our skilled, experienced, and motivated programmers who will work for you just like your in-house team to address your development challenges.
</h6>
                    </div>
                </div>

            </div>
            <GetRequirementForm 
            title="We can help you find the best developers for the job!"
            />
        </div>

    );
}

export default withRouter(hireDedicatedDeveloper);