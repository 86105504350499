import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from "react-router";
import icon1 from "../../images/commerce/icon1.svg"
import icon2 from "../../images/commerce/icon2.svg"
import icon3 from "../../images/commerce/icon3.svg"
import nowwith from "../../images/commerce/nowwith.png"
import headerimg from "../../images/commerce/headerimg.jpg" 

import './style.css';
import { Button } from "react-bootstrap";

import GetRequirementForm from "../../components/GetRequirementForm";

const commerceDevelopment = () => {
    return (
        <div className="content">
            <div className="header1" >
                <img src={headerimg}></img>
                <div className="comcentered">
                    <h1>eCommerce Development Company</h1>
                    <h3>We are a one-stop solution for all your eCommerce needs!
        </h3>
                    <h5>We are an acclaimed and certified web and mobile app design and<br />
            development company committed to digital innovations and<br /> inventions.</h5>
                   <center> <Button><a href="/getInTouch">Get a free quote</a> </Button></center>
                </div> 
            </div>
            <div className="development">
                <h1>Our eCommerce Development Process</h1>
                <p>We create fantastic e-Commerce solutions with our following effective<br />
eCommerce development process that converts more.</p>
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon1}></img>
                                    <div className="benefitsCardTitleText card-title h5">Planning</div>
                                    <p className="cardTextBenefits card-text">After getting project requirements, we discuss your
                                    project details with our technical analysts to understand your audience and build a
                                    well-defined buyer persona. Once done, we help you choose the right development platform
                                    for your project.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon2}></img>
                                    <div className="benefitsCardTitleText card-title h5">Implementation</div>
                                    <p className="cardTextBenefits card-text">We draw a complete development roadmap for your project
                                    and implement it with the right themes in this stage. After that, we start developing your
                                    product convenient for your shoppers. If needed, we enhance it and deploy it into the store.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon3}></img>
                                    <div className="benefitsCardTitleText card-title h5">Support</div>
                                    <p className="cardTextBenefits card-text">Once your app is deployed and need the optimisation of
                                    an e-commerce store or website, we provide complete support & maintenance to your product.
                                    We start troubleshooting it until all bugs and errors are fixed.

                       </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="marketplacecontent">
                <div className="commarketplace">
                    {/* <img src={nowwith}></img> */}
                    <div className="textblock">
                        <h3>Nowwith</h3>
                        <p>Engaging audiences across digital, linear cutting,
              and on-demand <br /> shopping-enabled platforms.
</p>
                        <Button><a href="http://nowwith.com/">View Project</a></Button>
                    </div>
                </div>
            </div>
            <div className="development">
                <h1>Our eCommerce Development Services</h1>
                <p>Ranked amongst the best eCommerce development company worldwide,
           we<br /> provide a wide range of eCommerce development services to both B2B & B2C
           <br /> customers all across the world.
</p>
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">CUSTOM ECOMMERCE DEVELOPMENT
                        </div>
                                    <p className="cardTextBenefits card-text">After getting project requirements, we discuss your
                                    project details with our technical analysts to understand your audience and build a
                                    well-defined buyer persona. Once done, we help you choose the right development platform
                                    for your project.


                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">MCOMMERCE <br /> DEVELOPMENT</div>
                                    <p className="cardTextBenefits card-text">We draw a complete development roadmap
                                    for your project and implement it with the right themes in this stage. After that,
                                    we start developing your product convenient for your shoppers. If needed, we enhance it
                                    and deploy it into the store.


                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">ECOMMERCE PLUG-INS & INTEGRATIONS
</div>
                                    <p className="cardTextBenefits card-text">Once your app is deployed and need the optimisation
                                    of an e-commerce store or website, we provide complete support & maintenance to your product.
                                    We start troubleshooting it until all bugs and errors are fixed.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">MIGRATION & <br />
UPGRADATION
</div>
                                    <p className="cardTextBenefits card-text">Want to migrate or upgrade from your existing
                                    eCommerce platform to the latest available technology? Hire our dedicated eCommerce
                                    development team who keep themselves updated with the latest advancements of eCommerce
                                    technologies which will help you with complete eCommerce platform migration and upgradation
                                    as per your requirements.


                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">TECHNOLOGY <br />CONSULTING</div>
                                    <p className="cardTextBenefits card-text">Being a top-notch eCommerce development company,
                                    we have established our reputation in providing high-quality eCommerce consulting services
                                    to clients worldwide. Our eCommerce technology consultants will help you find the right
                                    platform, technology, and framework for your project to save your time and cost.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">SUPPORT & <br />
MAINTENANCE

</div>
                                    <p className="cardTextBenefits card-text">With proven records of providing dedicated
                                    support & maintenance to our clients, we make sure that they get round-the-clock support
                                    and maintenance services so that their end-users can experience seamless and smooth online
                                    shopping. Contact us and hire our professionals to get top-of-the-line support & maintenance
                                    services.


                       </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="getintouch">
                <div className="row">
                    <div className="details1 col-md-4">
                        <h6>Get in touch</h6>
                        <p>with us to know more</p>
                    </div>
                    <div className="details2 col-md-8">
                        <h6>Leverage our expertise and stay ahead of your competitors with our
             enterprise-grade eCommerce web and mobile app development services.</h6>
                    </div>
                </div>

            </div>
            <GetRequirementForm 
            title = "Let’s build your app that never fails in delighting your customer!"
            />

        </div>

    );
}

export default withRouter(commerceDevelopment);