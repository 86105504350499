import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from "react-router";
import icon1 from "../../images/web/icon1.svg"
import icon2 from "../../images/web/icon2.svg"
import icon3 from "../../images/web/icon3.svg"
import webapp from "../../images/web/webapp.png"
import headerimg from "../../images/web/headerimg.jpg"  
import './style.css';
import { Button } from "react-bootstrap";
import GetRequirementForm from "../../components/GetRequirementForm";

 
const webAppDevelopment = () => {
    return (
        <div className="content">
            <div className="webheader" >
                <img src={headerimg}></img>
                <div className="webcentered">
                    <h1>Web App Development </h1>
                    <h3>Build custom software that helps your business thrive in the digital age!

        </h3>
                    <h5>Leverage our end-to-end web app development services to build secure,
                    robust, and user-friendly web applications, portals, or solutions in
accordance with your business requirements. </h5>
                    <center><Button><a href="/getInTouch">Get a free quote</a> </Button></center>
                </div>
            </div>
            <div className="development">
                <h1>Web App Development Service </h1>
                <p>Our unique web development process will help in bringing your ideas to life. We start the<br />
       process with in-depth research to testing, quality assurance and maintain a continuous<br />
        relationship until the post-launch support and maintenance.

</p>
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon1}></img>
                                    <div className="benefitsCardTitleText card-title h5">Product
Strategy</div>
                                    <p className="cardTextBenefits card-text">We understand your project requirements, business model, market challenges, and product positioning to create a complete wireframe for your digital product so that it can deliver a more excellent value when it grows.




                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon2}></img>
                                    <div className="benefitsCardTitleText card-title h5">Product
design</div>
                                    <p className="cardTextBenefits card-text">During the product design phase, we deploy our skilled and experienced designers, developers, and strategists to create a complete layout for your product as well as explore what trending technologies can be a right fit for your project.

                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">
                                    <img src={icon3}></img>
                                    <div className="benefitsCardTitleText card-title h5">Quality
assurance</div>
                                    <p className="cardTextBenefits card-text">At this step, our motivated and skilled development team deploys customized solutions and ensures the project’s code quality. Once done, we test every stage of product development to make your solution bug-free, safe and reliable.




                       </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="marketplacecontent">
                <div className="webmarketplace">
                    {/* <img src={webapp}></img> */}
                    <div className="textblock">
                        <h3>Truewrld</h3>
                        <p>Truewrld dedicated to giving you the very best, with a
                  focus on making<br /> you feel confident, stylish, and apart of a family.


</p>
                        <Button><a href="https://truewrld.com/">View Project</a></Button>
                    </div>
                </div>
            </div>
            <div className="development">
                <h1>Our Web App Development Services</h1>
                <p>We provide a wide range of web development services to start-ups, SMEs, and large-scale <br />enterprises
in order to achieve tangible results and navigate market complexities with our<br /> cutting-edge
web development solutions.



</p>
                <div className="process" >
                    <div className="row">

                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">CUSTOM WEB <br />
APPLICATION

</div>
                                    <p className="cardTextBenefits card-text">Leverage our web development team’s expertise and develop a tailor-made web application based on your unique business requirements. Our dedicated software developers will help you right from UI/UX design to concept actualization, development, and deployment stage.


                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">PROGRESSIVE WEB <br />DEVELOPMENT</div>
                                    <p className="cardTextBenefits card-text">Hire dedicated web experts from us to build secure, scalable, and high-performance applications that run smoothly and faster across all platforms. Our web developers are very well-versed at providing advanced progressive web app solutions to clients all across the world.



                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">ECOMMERCE <br />
DEVELOPMENT
</div>
                                    <p className="cardTextBenefits card-text">At Origami, we have a pool of talented designers and developers, having years of experience developing world-class e-commerce centric web applications to make your business stand out in the crowd. Get in touch with us and build top-notch eCommerce applications.




                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">WEB PORTAL <br />
DEVELOPMENT
</div>
                                    <p className="cardTextBenefits card-text">We have top-of-the-line expertise in building B2B & B2C shopping, job, matrimony, etc.; portals that can help you communicate with your customers quickly and effectively. Contact us today and create performance-oriented web solutions that take your business to the next level.




                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">THIRD PARTY API<br /> INTEGRATION

</div>
                                    <p className="cardTextBenefits card-text">Being a leading web development company, we have established our reputation in creating intelligent APIs, custom data connectors, web services, and middleware to integrate with third-party solutions and leverage web solutions seamlessly in the open web world.





                       </p>
                                </div>
                            </div>
                        </div>
                        <div className="benefitsCol col-md-4">
                            <div className="benefitsCardBox card">
                                <div className="card-body">

                                    <div className="benefitsCardTitleText card-title h5">UPGRADATION & <br />
MIGRATION
</div>
                                    <p className="cardTextBenefits card-text">Looking to upgrade or migrate your legacy or outdated web technology to the current web technology? If yes, we are the right fit for you. We have a skilled and experienced full-stack development team that is ready to help you with smooth and fast upgradation and migration.



                       </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="getintouch">
                <div className="row">
                    <div className="details1 col-md-4">
                        <h6>Get in touch</h6>
                        <p>with us to know more</p>
                    </div>
                    <div className="details2 col-md-8">
                        <h6>Do you have an app idea? We have highly competent developers that will help you build industry-specific web apps, leveraging a wide range of tools and technologies to scale your business operations.
</h6>
                    </div>
                </div>

            </div>
            <GetRequirementForm 
            title ="Let’s build your app that never fails in delighting your customer!"
            />
        </div>

    );
}

export default withRouter(webAppDevelopment);