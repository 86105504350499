import React, { useState } from 'react';
import contactsection from "../../images/services/contactsection.png"
import 'bootstrap/dist/css/bootstrap.css';
import Axios from "axios";
import "./styles.scss";
import {triggerDatainMixpanel} from "../../common";

const GetRequirementForm = (props) => {
  const { title } = props;

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [message, setMessage] = useState("")
  const [errorEmail, setErrorEmail] = useState("")
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const formSubmitClick = async () => {
    setThankyouMessage("")
    if (name != "" && email != "" && phoneNumber != "" && errorEmail === "") {
      var bostStr = `name=${name},email=${email},phoneNumber=${phoneNumber},message=${message}, `;
      try {
        const body = {
          body: bostStr,
          subject: "Talk To Us Information"+name,
          to: "likitha@myorigami.co"
        }
        const jsonData = {
          name,
          email,
          phoneNumber,
          message
        }
        setIsLoading(true)
        triggerDatainMixpanel("requirement_form_submit",jsonData)
        // const response = await Axios.post("http://52.165.36.192:8080/api/Email/Send", body);
        // console.log("vinod", response)
        setIsLoading(false)
        // if (response?.statusText === "OK") {
          setName(""); setEmail(""); setPhoneNumber(""); setMessage("");
          setThankyouMessage("Thank you, we will get back to you shortly.")
        // }
        // return response.data;
      } catch (error) {
        console.log(error);
      }
    } else {
      if (name === "")
        setEmptyName(true);
      if (email === "")
        setEmptyEmail(true);
      if (phoneNumber === "")
        setEmptyPhoneNumber(true);
    }
  }

  const onChangeValue = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value)
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.")
      } else {
        setErrorEmail("")
      }
    }
  }

  return (
    <div className="contact">
      <div className="row">
        <div className="consult col-md-6">
          <h2>{title} </h2>
          <p>Fill up form and schedule free consultation..</p>
          <img src={contactsection}></img>
        </div>
        <div className="consult col-md-6">
          <div class="contactform">

            <label for="name">Enter Name*</label>
            <input type="text" className={emptyName ? "error" : ""} onChange={(e)=>{setName(e.target.value);setEmptyName(false)}} id="name" value={name} name="name" placeholder="Enter Name"></input>

            <label for="phoneNumber" >Telephone Number*</label>
            <input type="text" className={emptyPhoneNumber ? "error" : ""} maxLength={10} onChange={(e) => {
              setEmptyPhoneNumber(false)
              const re = /^[0-9\b]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setPhoneNumber(e.target.value)
              }
            }} id="phoneNumber" value={phoneNumber} name="phoneNumber" placeholder="Enter Telephone Number"></input>

            <label for="email">Email*</label>
            <input type="text" className={emptyEmail ? "error" : ""} onChange={(e)=>{onChangeValue(e);setEmptyEmail(false)}} id="email" value={email} name="email" placeholder="Enter Email Id"></input>
            {errorEmail != "" && (
              <p className="emailError">{errorEmail}</p>
            )}

            <label for="message">Requirement*</label>
            <textarea id="message" onChange={(e)=>{setMessage(e.target.value)}} id="message" value={message} name="message" placeholder="Write Your Requirement Here..." ></textarea>

            {thankyouMessage != "" && (
              <p className="successText">{thankyouMessage}</p>
            )}
            <input type="submit" onClick={formSubmitClick} value={isLoading ? "Loading..." : "Submit"}></input>

          </div>
        </div>
      </div>

    </div>
  )
}
export default GetRequirementForm;
