import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import line from "../../images/services/line.png"
import carrer from "../../images/services/carrer.png"
import './style.css';
import { Button } from "react-bootstrap";



const Carrers = () => {
    // const positionList = ["Senior .net developer", "Graphic Designer", "Senior .net developer"]
    const [jon1DetailsOpen, setJon1DetailsOpen] = useState(false);
    const [jon2DetailsOpen, setJon2DetailsOpen] = useState(false);
    const [jon3DetailsOpen, setJon3DetailsOpen] = useState(false);
    return (
        <div className="content">

            <div className="carrer">
                <div className="row">
                    <div className="carrernew1 col-md-6">
                        <div className="row">
                            <h4>CAREERS <img src={line}></img></h4>
                            <h1>Create <span>Awesome things</span> with us!</h1>
                            <p>We’re always eager to meet fresh talent, so check out our open positions.</p>
                            <Button>VIEW OPENINGS</Button>
                        </div>


                    </div>
                    <div className="carrernew2 col-md-6">
                        <img src={carrer}></img>
                    </div>
                </div>

            </div>

            <div className="join">
                <h5>Join Us <img src={line}></img></h5>
                <div className="row">
                    <div className="joincontent1 col-md-6">

                        <h2>Lead your <span>professional career</span> with Origami!</h2>
                    </div>
                    <div className="joincontent2 col-md-6">
                        <p>Origami Softwares brings wonderful career opportunities for those aspirants,
                            who want to start their career in the web development industry. We are devoted
                            to maximizing the intuitive skills of our employees and retaining them without
                            any discrimination on the basis of creed, race, color, region, citizenship,
                            disability, genetic information, etc.</p>
                    </div>



                </div>

            </div>
            <div className="vacancy">
                <h5>Careers <img src={line}></img></h5>
                <h2>Open Vacancies</h2>
                <div className="benefitsCol">
                    <div className="benefitsCardBox card">
                        <div className="card-body">

                            <div className="developer">
                                <div className="row">
                                    <div className="vacancycontent1 col-md-8">
                                        <div className="head" >
                                            <h4>Node Js Developer</h4>
                                            <p>We are Looking for candidate who have expertise skills at NodeJs with proven hands on experience for product Development or enterprise level application.</p>
                                        </div>
                                        <div className="text">
                                            <h6><b>Mandatory:</b></h6>
                                            <ul>
                                                <li>NodeJS</li>
                                                <li>AWS Serverless , IAM Management,API Gateways</li>
                                                <li>AWS Lambda,SNS ,SQS, Route 53 and EC2</li>
                                                <li>JavaScript</li>
                                                {jon1DetailsOpen && (<>
                                                    <li>Third party API Integrations ( Ex: payment gateways, OMS ,CMS)</li>
                                                    <li>Expertise in maintaining coding standards and contribute to system makes scalable and reliable</li>
                                                    <li>problem solving</li>
                                                    <li>Prepare Unit test cases for the services - JEST/MOCHA/CHAI</li>
                                                    <li>Any code version control - GIT/SVN/Bit Bucket</li>
                                                    <br /> </>)}



                                            </ul>
                                            {jon1DetailsOpen && (<>
                                                <h6><b>Good To Have:</b></h6>
                                                <ul>
                                                    <li>Basic understanding of front-end technologies, such as HTML5, and CSS3 </li>
                                                    <li>Analytical thinking </li>
                                                    <li>Able to accept challenge and working on poc to make the robust system </li>
                                                    <li>Able to Adapt as per the project execution plan. </li>
                                                    <li> Knowledge or hands on CI/CD for AWS (edited) </li>
                                                </ul></>)}
                                        </div>
                                    </div>
                                    <div className="vacancycontent2 col-md-4">
                                        <div className="row">
                                            <div className="side">
                                                <Button><a href="mailto:hr@myorigami.co">Apply</a></Button>
                                                {jon1DetailsOpen ?
                                                    <Button onClick={() => { setJon1DetailsOpen(false) }} style={{ marginLeft: '30px', backgroundColor: '#fff', color: '#000', }}>Close Details</Button>
                                                    :
                                                    <Button onClick={() => { setJon1DetailsOpen(true) }} style={{ marginLeft: '30px', backgroundColor: '#fff', color: '#000', }}>View Details</Button>
                                                }
                                            </div>
                                        </div>
                                    </div>



                                </div></div>
                        </div>
                    </div>
                </div>

                <div className="benefitsCol">
                    <div className="benefitsCardBox card">
                        <div className="card-body">

                            <div className="developer">
                                <div className="row">
                                    <div className="vacancycontent1 col-md-8">
                                        <div className="head" >
                                            <h4>React JS Developer</h4>
                                            <p>4 + years of experience in React JS.</p>
                                        </div>
                                        <div className="text">
                                            <h6><b>Required Skills:</b></h6>
                                            <ul>
                                                <li>Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model</li>
                                                <li>Understanding of using Typescript and ReactJS and its core principles</li>
                                                <li>Strong experience with popular React.js workflows (Redux)</li>
                                                <li>Familiar with newer specifications of ES6</li>
                                                {jon2DetailsOpen && (<>
                                                    <li>Familiar with RESTful APIs</li>
                                                    <li>Knowledge of HTML , CSS</li>
                                                    <li>Knowledge of modern authorization mechanisms, such as JSON Web Token</li>
                                                    <li>Familiar with code versioning tools (such as Git, SVN)</li>
                                                </>)}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="vacancycontent2 col-md-4">
                                        <div className="row">
                                            <div className="side">
                                                <Button><a href="mailto:hr@myorigami.co">Apply</a></Button>
                                                {jon2DetailsOpen ?
                                                    <Button onClick={() => { setJon2DetailsOpen(false) }} style={{ marginLeft: '30px', backgroundColor: '#fff', color: '#000', }}>Close Details</Button>
                                                    :
                                                    <Button onClick={() => { setJon2DetailsOpen(true) }} style={{ marginLeft: '30px', backgroundColor: '#fff', color: '#000', }}>View Details</Button>
                                                }
                                            </div>
                                        </div>
                                    </div>



                                </div></div>
                        </div>
                    </div>
                </div>

                <div className="benefitsCol">
                    <div className="benefitsCardBox card">
                        <div className="card-body">

                            <div className="developer">
                                <div className="row">
                                    <div className="vacancycontent1 col-md-8">
                                        <div className="head" >
                                            <h4>User Experience Designer</h4>
                                        </div>
                                        <div className="text">
                                            <h6><b>Roles and Responsibilities</b></h6>
                                            <ul>
                                                <li>Creating user-centred designs by understanding business requirements, and user feedback</li>
                                                <li>Creating user flows, wireframes, prototypes and mockups</li>
                                                <li>Translating requirements into style guides, design systems, design patterns and attractive user interfaces</li>
                                                <li>Designing UI elements such as input controls, navigational components and informational components</li>
                                                {jon3DetailsOpen && (<>
                                                    <li>Creating original graphic designs (e.g. images, sketches and tables)</li>
                                                    <li>Identifying and troubleshooting UX problems (e.g. responsiveness)</li>
                                                    <li>Collaborating effectively with the product, engineering, and management teams</li>
                                                    <li>Incorporating customer feedback, usage metrics, and usability findings into the design to enhance user experience</li>
                                                    <br /></>)}


                                            </ul>
                                            {jon3DetailsOpen && (<>
                                                <h6><b>Desired Candidate Profile</b></h6>
                                                <ul>
                                                    <li>A degree/diploma in Design, Fine Arts, Engineering or related field</li>
                                                    <li>Prior experience as a UI/UX Designer as well as a strong portfolio of related projects</li>
                                                    <li>You are proficient in Adobe Creative Suite (specifically Illustrator, InDesign and Photoshop)</li>
                                                    <li>You are proficient in prototyping tools such as Sketch, InVision, Figma etc.</li>
                                                    <li>You pay strong attention to detail and have a keen eye for aesthetics</li>
                                                    <li>You have excellent communication skills and can clearly articulate your ideas, designs, and suggestions</li>
                                                    <li>You are a strong team player who can collaborate effectively with different stakeholders</li>
                                                </ul></>)}
                                        </div>
                                    </div>
                                    <div className="vacancycontent2 col-md-4">
                                        <div className="row">
                                            <div className="side">
                                                <Button><a href="mailto:hr@myorigami.co">Apply</a></Button>
                                                {jon3DetailsOpen ?
                                                    <Button onClick={() => { setJon3DetailsOpen(false) }} style={{ marginLeft: '30px', backgroundColor: '#fff', color: '#000', }}>Close Details</Button>
                                                    :
                                                    <Button onClick={() => { setJon3DetailsOpen(true) }} style={{ marginLeft: '30px', backgroundColor: '#fff', color: '#000', }}>View Details</Button>
                                                }
                                            </div>
                                        </div>
                                    </div>



                                </div></div>
                        </div>
                    </div>
                </div>


            </div>


        </div>

    );
}

export default Carrers;