import React , { useState , useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Axios from "axios";
import "./style.css"
import {triggerDatainMixpanel} from "../../common";
const GetInTouch = () => {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [message, setMessage] = useState("")
  const [errorEmail, setErrorEmail] = useState("")
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

useEffect(()=>{
  triggerDatainMixpanel("get_in_touch_page_load")
}, [])
  const formSubmitClick = async ()=>{
    setThankyouMessage("")
    if(name !="" && email!="" && phoneNumber!="" && errorEmail===""){
    var bostStr = `name=${name},email=${email},phoneNumber=${phoneNumber},message=${message}, `;
    try {
      const body ={
        body: bostStr,
        subject: "Talk To Us Information "+name,
        to: "vinod@myorigami.co"
      }
      setIsLoading(true)
      const jsonData = {
        name,
        email,
        phoneNumber,
        message
      }

      triggerDatainMixpanel("get_in_touch_form_submit",jsonData)

      // const response = await Axios.post("http://52.165.36.192:8080/api/Email/Send", body);
        // console.log("vinod", response)
        setIsLoading(false)
      // if(response?.statusText === "OK"){
        setName("");setEmail("");setPhoneNumber("");setMessage("");
        setThankyouMessage("Thank you, we will get back to you shortly.")
      // }
      // return response.data;
    } catch (error) {
      console.log(error);
    }
  }else{
    if(name==="")
    setEmptyName(true);
    if(email==="")
    setEmptyEmail(true);
    if(phoneNumber==="")
    setEmptyPhoneNumber(true);
  }
  }

  const onChangeValue = (e)=>{
    if(e.target.name === "email"){
      setEmail(e.target.value)
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.")
      }else{
        setErrorEmail("")
      }
    }
  }
  
  return (
    <div className="content getinTouchPage">

      <div className="contact">
        <div className="row">

          <div className="consultnew col-md-6">
            <h1>We create award winning software solutions</h1>
            <p>Being a technology company, we convert your ideas into reality. We offer Product Consultancy Services, MVP Services, Staff Augmentation Services, App and Web Design & Development Services to help you build solutions that are Smart, User friendly and well Designed to make you successfully achieve your goals.</p>
          </div>
          <div className="consultnew col-md-6">
            <h2>Talk To Us</h2>
            <h6>Let's discuss your plans and goals</h6>
            <div class="contactformnew">
              
                <input type="text" className={emptyName ? "error" : ""} onChange={(e)=>{setName(e.target.value);setEmptyName(false)}} id="name" value={name} name="name" placeholder="Name*"></input>
                <input type="text" className={emptyEmail ? "error" : ""} onChange={(e)=>{onChangeValue(e);setEmptyEmail(false)}}  id="email" value={email} name="email" placeholder="Email*"></input>
                {errorEmail !="" && (
                  <p className="emailError">{errorEmail}</p>
                )}
                <input type="text"className={emptyPhoneNumber? "error" : ""}  maxLength={10} onChange={(e)=>{
                        setEmptyPhoneNumber(false)
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setPhoneNumber(e.target.value)
                        }
                  }}  id="phoneNumber" value={phoneNumber} name="phoneNumber" placeholder="Phone Number*"></input>
                <textarea type="text" onChange={(e)=>{setMessage(e.target.value)}}  id="message" value={message} name="message" placeholder="Your Message"></textarea>
                {/* <label for="budget">What have you budgeted for this project?</label>
                  <select id="budget" name="budget">
                    <option value="Select your Budget">Select your Budget</option>
                    
                  </select>

                
                  <input type="checkbox" id="terms" name="terms" value="terms"></input>
                <label for="terms">&nbsp;&nbsp;I agree to receive  Occasional Origami newsletters.</label> */}
              
              {thankyouMessage !="" && (
                  <p className="successText">{thankyouMessage}</p>
                )}
                <input type="submit" onClick={formSubmitClick} value={isLoading ? "Loading..." : "Submit"}></input>
                
            </div>
          </div>
        </div>

      </div>
 
    </div>

  );
}

export default GetInTouch;